import { Button, Form, Input, message, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { authStore } from '../../model/auth';

export const LoginModal = observer(() => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Listen for auth:loginFailed event to reset loading state
  useEffect(() => {
    const handleLoginFailed = () => {
      setLoading(false);
    };

    document.addEventListener('auth:loginFailed', handleLoginFailed);

    return () => {
      document.removeEventListener('auth:loginFailed', handleLoginFailed);
    };
  }, []);

  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      setLoading(true);

      await authStore.login(values);

      // Use explicit hide method instead of toggle
      authStore.hideLoginModal();
      form.resetFields();
    } catch (error: any) {
      message.error(error.message || 'Failed to login');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Login"
      open={authStore.isLoginModalOpen}
      onCancel={authStore.hideLoginModal}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email!' }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Please input your password!' },
            { min: 8, message: 'Password must be at least 8 characters!' }
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Login
          </Button>
        </Form.Item>

        {!authStore.isDirectMode && (
          <div style={{ textAlign: 'center' }}>
            <span>Don't have an account? </span>
            <Button
              type="link"
              onClick={() => {
                authStore.hideLoginModal();
                authStore.showSignupModal();
              }}
            >
              Sign up
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
});
