import React, { useEffect, useState } from 'react';
import { AppViewModel } from "../../AppViewModel";
import './CombinedSentenceSection.css';
import { ColorMode, ColorScheme, IAppState, SentenceCase, SentenceSplit } from "../../model/state";
import { ISectionTitle, Section } from '../Components/Section';
import { CurrentSentence } from './CurrentSentence/CurrentSentence';
import { NewSentence } from './NewSentence/NewSentence';

interface ICombinedSentenceSectionProps {
    dev: boolean;
    state: IAppState;
    vm: AppViewModel;
    onStartGameClick: () => void;
    onSentenceCaseToggle: (checked: boolean) => void;
    onSentenceCaseChange: (sentenceCase: SentenceCase) => void;
    onSentenceSplitToggle: (checked: boolean) => void;
    onSentenceSplitChange: (sentenceSplit: SentenceSplit) => void;
    onSelectionClick: (start: number, end: number) => void;
    onFontChange: (font: string) => void;
    onColorsChange: (colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[]) => void;
    onBackgroundChange?: (background: string) => void;
    gameState: any; // For NewSentence
    onSubmitSentence: (sentence: string, splits: number[], font: string, colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[], sentenceCase: SentenceCase, sentenceSplit: SentenceSplit, background: string) => void; // For NewSentence
    initialStateReceived?: boolean;
    isWaitingForState?: boolean;
}

export const CombinedSentenceSection: React.FC<ICombinedSentenceSectionProps> = (props) => {
    const [activeTabId, setActiveTabId] = useState<string>('current');

    // Effect to focus the input when the tab changes to 'new'
    useEffect(() => {
        if (activeTabId === 'new') {
            // Use setTimeout to ensure the tab switch is complete
            setTimeout(() => {
                // Find the input element in the NewSentence component and focus it
                const inputElement = document.querySelector('.new-sentence-wrapper input') as HTMLInputElement;
                if (inputElement) {
                    inputElement.focus();
                    console.log("Input focused via document.querySelector");
                } else {
                    console.log("Input element not found");
                }
            }, 100);
        }
    }, [activeTabId]);

    const titles: ISectionTitle[] = [
        {
            id: 'current',
            title: 'Obecne Zdanie',
            activeTitle: 'Obecne Zdanie',
            inactiveTitle: 'Obecne',
            isActive: activeTabId === 'current'
        },
        {
            id: 'new',
            title: 'Nowe Zdanie',
            activeTitle: 'Nowe Zdanie',
            inactiveTitle: 'Nowe',
            isActive: activeTabId === 'new'
        }
    ];

    const handleTitleClick = (titleId: string) => {
        setActiveTabId(titleId);
    };

    const handleSubmitSentence = (sentence: string, splits: number[], font: string, colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[], sentenceCase: SentenceCase, sentenceSplit: SentenceSplit, background: string) => {
        // After submitting, switch back to the current sentence tab
        setActiveTabId('current');
        props.onSubmitSentence(sentence, splits, font, colorMode, colorScheme, colors, userColors, sentenceCase, sentenceSplit, background);
    };

    const contentMap: Record<string, React.ReactNode> = {
        'current': (
            <div className="current-sentence-container">
                <CurrentSentence
                    state={props.state}
                    vm={props.vm}
                    onSentenceCaseToggle={props.onSentenceCaseToggle}
                    onSentenceCaseChange={props.onSentenceCaseChange}
                    onSentenceSplitToggle={props.onSentenceSplitToggle}
                    onSentenceSplitChange={props.onSentenceSplitChange}
                    onSelectionClick={props.onSelectionClick}
                    onFontChange={props.onFontChange}
                    onColorsChange={props.onColorsChange}
                    onBackgroundChange={props.onBackgroundChange}
                />
                {props.isWaitingForState && (
                    <div className="loading-overlay">
                        <div className="loading-spinner"></div>
                        <div className="loading-text">Ładowanie...</div>
                    </div>
                )}
            </div>
        ),
        'new': (
            <NewSentence
                gameState={props.gameState}
                onSubmitSentence={handleSubmitSentence}
                dev={props.dev}
                initialStateReceived={props.initialStateReceived}
                isActive={activeTabId === 'new'}
                vm={props.vm}
            />
        )
    };

    return (
        <Section
            titles={titles}
            onTitleClick={handleTitleClick}
            contentMap={contentMap}
        />
    );
};
