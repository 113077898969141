import classNames from "classnames";
import { observer } from "mobx-react";
import React from 'react';
import { ColorMode, SentenceCase, SentenceSplit } from "../../../model/state";
import { splitAtIndexes } from "../../../syllabes/utils";
import "./SentencePreviewWord.css";
import { SentenceMode } from "../shared/SentenceEnums";


export interface ISentencePreviewWordProps {
    word: string;
    splits: number[];
    font: string;
    sentenceCase: SentenceCase;
    sentenceSplit: SentenceSplit;
    offset: number;
    colors: string[];
    colorMode: ColorMode;
    sentenceMode: SentenceMode;
    onSelectionClick?: (start: number, end: number) => void;
    onCharacterClick?: (index: number) => void;
    selection: { start: number, end: number } | null;
}

@observer
export class SentencePreviewWord extends React.Component<ISentencePreviewWordProps> {
    public render() {
        const {
            sentenceMode,
            selection,
            offset,
            word,
            splits,
            font,
            sentenceSplit,
            sentenceCase,
            colors,
            colorMode,
            onCharacterClick
        } = this.props;
        const wordSplits = splits.filter(s => s >= offset && s < offset + word.length).map(s => s - offset);
        const syllabes = splitAtIndexes(word, wordSplits);

        const isWordSelected = sentenceMode === SentenceMode.SelectWord
            && selection?.start === offset
            && selection.end === offset + word.length

        const wordClass = classNames("sentence-preview-word", {
            "sentence-preview-word-select-mode": sentenceMode === SentenceMode.SelectWord,
            "sentence-preview-word-selected": isWordSelected
        });

        let letterOffset = 0;
        let colorOffset = 0;
        const showDash = sentenceSplit === SentenceSplit.Show;
        const uppercase = sentenceCase === SentenceCase.Uppercase;

        let wordStyle = {};
        if (colorMode === ColorMode.Word) {
            wordStyle = {color: "#" + colors[colorOffset++]}
        }
        return (
            <div style={{fontFamily: font, color: "#56cfff"}} className="text-outline sentence-preview-word-wrapper">
                <div style={{position: 'absolute', color: 'transparent', zIndex: 0}} className={wordClass}
                     onClick={this.onWordClick}>
                    {syllabes.map((syl, i) => {
                        const isSyllabeSelected = sentenceMode === SentenceMode.SelectSyllabe
                            && selection?.start === offset + letterOffset
                            && selection.end === offset + letterOffset + syl.length;
                        const sylClass = classNames("sentence-preview-syllabe", {
                            "sentence-preview-syllabe-select-mode": sentenceMode === SentenceMode.SelectSyllabe,
                            "sentence-preview-syllabe-selected": isSyllabeSelected
                        });
                        return (
                            <span key={"syl" + i}>
                                <span key={"syl" + i + "_active"} className={sylClass}
                                      onClick={this.onSyllabeClick(letterOffset, syl.length)}>
                                    {syl.split("").map((l, j, full) => {
                                        const isLetterSelected = sentenceMode === SentenceMode.SelectLetter
                                            && selection?.start === offset + letterOffset
                                            && selection.end === offset + letterOffset + 1;

                                        const letterClass = classNames("sentence-preview-letter", {
                                            "sentence-preview-letter-select-mode": sentenceMode === SentenceMode.SelectLetter,
                                            "sentence-preview-letter-selected": isLetterSelected,
                                            "sentence-preview-letter-split-not-last": sentenceMode === SentenceMode.Splits && (j < full.length - 1 || i < syllabes.length - 1),
                                        });

                                        const elem = <span key={j} className={letterClass}
                                                           onClick={this.onLetterClick(letterOffset)}>{uppercase ? l.toUpperCase() : l}</span>

                                        letterOffset++;
                                        return elem;
                                    })}
                                </span>
                                {i !== syllabes.length - 1 && showDash &&
                                    <span key={"dash" + letterOffset}
                                          className={classNames({"sentence-preview-dash-splits": sentenceMode === SentenceMode.Splits})}
                                          onClick={this.onDashClick(letterOffset - 1)}>-</span>}
                            </span>
                        )
                    })}
                </div>

                <div style={{position: 'relative', zIndex: 1, pointerEvents: 'none', ...wordStyle}}>
                    {syllabes.map((syl, i) => {
                        let syllabeStyle = {};
                        if (colorMode === ColorMode.Syllabe) {
                            syllabeStyle = {color: "#" + colors[colorOffset++]}
                        }
                        return (
                            <span key={"syl" + i}>
                                <span key={"syl" + i + "_passive"} style={syllabeStyle}>
                                    {syl.split("").map((l, j) => {
                                        let letterStyle = {};
                                        if (colorMode === ColorMode.Letter) {
                                            letterStyle = {color: "#" + colors[colorOffset++]}
                                        }
                                        const elem = <span style={letterStyle}
                                                           key={j}>{uppercase ? l.toUpperCase() : l}</span>;
                                        letterOffset++;
                                        return elem;
                                    })}
                                </span>
                                {i !== syllabes.length - 1 && showDash && (
                                    <span key={"dash" + letterOffset}
                                          className={classNames({"sentence-preview-dash-splits": sentenceMode === SentenceMode.Splits})}>
                                        -
                                    </span>
                                )}
                            </span>
                        )
                    })}
                </div>
            </div>
        )
    }

    private onWordClick = () => {
        if (this.props.sentenceMode === SentenceMode.SelectWord) {
            this.props.onSelectionClick?.(this.props.offset, this.props.offset + this.props.word.length);
        }
    }

    private onSyllabeClick = (offset: number, length: number) => () => {
        if (this.props.sentenceMode === SentenceMode.SelectSyllabe) {
            this.props.onSelectionClick?.(this.props.offset + offset, this.props.offset + offset + length);
        }
    }

    private onLetterClick = (offset: number) => () => {
        if (this.props.sentenceMode === SentenceMode.SelectLetter) {
            this.props.onSelectionClick?.(this.props.offset + offset, this.props.offset + offset + 1);
        }
        this.props.onCharacterClick?.(this.props.offset + offset);
    }

    private onDashClick = (offset: number) => () => {
        this.props.onCharacterClick?.(this.props.offset + offset);
    }
}
