import {Subject} from 'rxjs';
import { OnAuthResult, OnError, OnLogout } from "./messageBus";
import {
    IncomingType,
    OutgoingType,
    IncomingMessage, OutgoingMessage, ServerConnectMessage
} from './messageTypes';

export enum WSState {
    Open,
    Closed,
    Error,
    None
}

// Define the runtime configuration interface that the child application injects
interface RuntimeConfig {
    isDirectMode: boolean;
    websocketPort: number;
}

// Access the runtime configuration if available
declare global {
    interface Window {
        __RUNTIME_CONFIG__?: RuntimeConfig;
    }
}

class WSModel {
    public onMessage: Subject<MessageEvent>;
    public onOpen: Subject<void>;
    public onClose: Subject<void>;
    public onError: Subject<void>;

    private wsBaseUrl = process.env.REACT_APP_WS_BASE_URL!;

    private ws?: WebSocket;
    private reconnectTimeout?: number;
    private pingTimeout?: number;  // For keeping track of ping/pong timeout
    private shouldAutoReconnect: boolean = true;

    private state2: WSState = WSState.None;

    private _directMode: boolean = false;

    // Store the refresh token instead of access token
    private refreshToken?: string;

    constructor() {
        this.onMessage = new Subject<MessageEvent>();
        this.onOpen = new Subject<void>();
        this.onClose = new Subject<void>();
        this.onError = new Subject<void>();

        // Initialize once the DOM is fully loaded to ensure runtime config is available
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => {
                this.detectDirectMode();
            });
        } else {
            // DOM is already loaded
            this.detectDirectMode();
        }
    }

    private setState = (state: WSState) => {
        this.state2 = state;
    }

    private detectDirectMode() {
        // Check for runtime configuration
        if (window.__RUNTIME_CONFIG__?.isDirectMode) {
            this._directMode = true;
            console.log('Using direct mode WebSocket connection');
            // In direct mode, establish connection immediately
            this.createWS();
        } else {
            console.log('Using remote WebSocket connection - waiting for authentication');
            // In remote mode, connection will be established after authentication
        }
    }

    public get isOpen() {
        return this.state2 === WSState.Open;
    }

    get isDirectMode(): boolean {
        return this._directMode;
    }

    public get currentWsUrl() {
        // Return the actual URL being used, either from runtime config or environment
        if (this.isDirectMode && window.__RUNTIME_CONFIG__?.websocketPort) {
            return `ws:\\${window.location.hostname}:${window.__RUNTIME_CONFIG__.websocketPort}`
        }
        return this.wsBaseUrl;
    }

    /**
     * Reset ping timeout - called when any message is received from the server
     * or when a ping is received
     */
    private resetPingTimeout() {
        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
        }

        // Set a timeout slightly longer than the server's ping interval (30s)
        this.pingTimeout = window.setTimeout(() => {
            // Connection lost - close it and try to reconnect
            this.closeConnection();

            // Trigger reconnect if auto reconnect is enabled
            if (this.shouldAutoReconnect) {
                this.reconnectTimeout = window.setTimeout(() => {
                    this.createWS();
                }, 2000);
            }
        }, 45000); // 45 seconds timeout (server sends pings every 30s)
    }

    /**
     * Disconnect WebSocket and prevent automatic reconnection
     * Should be called on logout
     */
    public disconnect = () => {
        console.log('Disconnecting WebSocket and preventing automatic reconnection');
        this.shouldAutoReconnect = false;
        this.closeConnection();
        this.setState(WSState.None);
    }

    /**
     * Send a message through the WebSocket connection
     * @param message The message to send
     */
    public send = (message: OutgoingMessage) => {
        if (this.ws?.readyState === WebSocket.OPEN) {
            // Ensure message has a type
            if (!message.type) {
                console.error("Message missing required 'type' field:", message);
                return;
            }

            // Create a copy of the message to avoid modifying the original
            const messageCopy = { ...message };

            // Don't log ping/pong messages to keep logs cleaner
            if (messageCopy.type !== OutgoingType.PONG) {
                console.log(`[SEND] ${this._directMode ? 'DIRECT' : 'REMOTE'} ${messageCopy.type}`, messageCopy);
            }

            this.ws?.send(JSON.stringify(messageCopy));
        } else {
            console.warn("Trying to send message but websocket not available:", message);
        }
    }

    private closeConnection() {
        console.log("closeConnection");

        // Clear ping timeout
        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
            this.pingTimeout = undefined;
        }

        // Clear any pending reconnect
        if (this.reconnectTimeout) {
            window.clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = undefined;
        }

        // Close the existing connection if any
        if (this.ws) {
            // Remove event handlers to prevent triggering reconnect
            this.ws.onclose = null;
            this.ws.onerror = null;
            this.ws.onopen = null;
            this.ws.onmessage = null;

            // Close the connection if it's not already closed
            if (this.ws.readyState === WebSocket.OPEN || this.ws.readyState === WebSocket.CONNECTING) {
                this.ws.close();
            }

            this.ws = undefined;
        }
    }

    private createWS() {
        if (this.ws && this.isOpen) {
            return;
        }
        console.log("Creating Websocket connection")

        try {
            this.ws = new WebSocket(this.currentWsUrl);
            this.ws.onopen = this.onOpenHandler;
            this.ws.onclose = this.onCloseHandler;
            this.ws.onerror = this.onErrorHandler;
            this.ws.onmessage = this.onMessageHandler;

        } catch (error) {
            console.error('Error creating WebSocket connection:', error);
            this.onError.next();

            // Schedule reconnect
            if (this.shouldAutoReconnect) {
                this.reconnectTimeout = window.setTimeout(() => {
                    this.createWS();
                }, 3000);
            }
        }
    }

    private onMessageHandler = (e: MessageEvent) => {
        // Reset the ping timeout whenever we receive any message
        this.resetPingTimeout();

        // First, pass the message to the general message observer
        this.onMessage.next(e);

        // Then try to parse and handle message
        try {
            const data: IncomingMessage = JSON.parse(e.data);

            // Handle application-level PING messages
            if (data.type === IncomingType.PING) {
                // Respond with a PONG message without logging
                this.send({
                    type: OutgoingType.PONG,
                    timestamp: data.timestamp
                });
                return;
            }

            switch (data.type) {
                case IncomingType.DIRECT_LOGIN_PARENT:
                    const authResult = data;
                    OnAuthResult.next(authResult);
                    break;
                case IncomingType.SERVER_CONNECTION_CONFIRMED:
                    // Handle connection confirmation

                    if (!data.success) {
                        console.error('WebSocket authentication failed:', data.error);
                        // Trigger logout if there's a token error
                        OnLogout.next(null);
                    }
                    break;
                case IncomingType.SYNC_STATE:
                    console.log('Received state update:', data);
                    break;
                case IncomingType.DIRECT_LOGOUT_PARENT:
                    console.log('Received logout notification from child application');
                    OnLogout.next(null);
                    break;
                default:
                    OnError.next({message: `Unsupported message type: ${data.type}`});
            }

            // Log the received message (except ping/pong messages)
            console.log(`[RECEIVE] ${this._directMode ? 'DIRECT' : 'REMOTE'} ${data.type}`, data);
        } catch (error) {
            console.error('Error parsing message:', error, e.data);
        }
    }

    private onCloseHandler = (ev: CloseEvent) => {
        console.log('WebSocket connection closed', ev);
        this.setState(WSState.Closed);
        this.onClose.next();

        // Clear ping timeout on close
        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
            this.pingTimeout = undefined;
        }

        // Schedule reconnect if auto reconnect is enabled
        if (this.shouldAutoReconnect) {
            this.reconnectTimeout = window.setTimeout(() => {
                this.createWS();
            }, 2000);
        }
    }

    private onOpenHandler = () => {
        console.log('WebSocket connection opened');
        this.setState(WSState.Open);
        this.onOpen.next();

        // Start the ping timeout as soon as the connection is open
        this.resetPingTimeout();

        // If we have an access token and are in remote mode, send CONNECT message
        if (this.refreshToken && !this._directMode) {
            console.log('Sending SERVER_CONNECT message with authentication token');
            this.connectToRemoteServer(this.refreshToken);
        }
    }

    private onErrorHandler = (error: Event) => {
        console.error('WebSocket connection error:', error);
        this.setState(WSState.Error);
        this.onError.next();
    }

    public createAuthenticatedConnection = (token: string) => {
        if (this._directMode) {
            console.error('Direct mode: This shouldn\'t be called');
            return;
        }

        console.log('Creating authenticated WebSocket connection with token');
        this.refreshToken = token;
        this.createWS();
    }

    private connectToRemoteServer = (token: string) => {
        if (!this._directMode && this.isOpen) {
            console.log(`Sending SERVER_CONNECT message to remote server. Token ${token ? 'is provided' : 'is not provided'}`);

            const message: ServerConnectMessage = {
                type: OutgoingType.SERVER_CONNECT, // J_1
                token
            };

            this.send(message);
        }
    }
}

export const WS = new WSModel();
