import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, message, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { authStore } from '../../model/auth';

export const SignupModal = observer(() => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values: { email: string; password: string; name: string }) => {
    try {
      setLoading(true);
      await authStore.register(values);
      authStore.hideSignupModal();
      form.resetFields();
      message.success('Successfully signed up!');
    } catch (error: any) {
      message.error(error.response?.data?.error || 'Failed to sign up');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    // TODO: Implement Google OAuth
    message.info('Google login coming soon!');
  };

  const handleFacebookLogin = async () => {
    // TODO: Implement Facebook OAuth
    message.info('Facebook login coming soon!');
  };

  return (
    <Modal
      title="Sign Up"
      open={authStore.isSignupModalOpen}
      onCancel={authStore.hideSignupModal}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email!' }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Please input your password!' },
            { min: 8, message: 'Password must be at least 8 characters!' }
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Sign Up
          </Button>
        </Form.Item>

        <Divider>Or continue with</Divider>

        <div style={{ display: 'flex', gap: '8px' }}>
          <Button icon={<GoogleOutlined />} onClick={handleGoogleLogin} block>
            Google
          </Button>
          <Button icon={<FacebookOutlined />} onClick={handleFacebookLogin} block>
            Facebook
          </Button>
        </div>

        <div style={{ textAlign: 'center', marginTop: '16px' }}>
          <span>Already have an account? </span>
          <Button
            type="link"
            onClick={() => {
              authStore.hideSignupModal();
              authStore.showLoginModal();
            }}
          >
            Login
          </Button>
        </div>
      </Form>
    </Modal>
  );
});
