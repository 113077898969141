import { getWords } from "../syllabes/utils";
import { ColorMode } from "./state";

interface HSV {
    r: number;
    g: number;
    b: number;
}

export const numberOfColors = (sentence: string, splits: number[], colorMode: ColorMode) => {
    let numberOfColors;
    const words = getWords(sentence);
    switch (colorMode) {
        case ColorMode.Word:
            numberOfColors = words.length;
            break;
        case ColorMode.Syllabe:
            numberOfColors = words.length + splits.length;
            break;
        case ColorMode.Letter:
            numberOfColors = words.reduce((sum, str) => sum + str.length, 0)
            break;

    }

    return numberOfColors;
}

export const repeatArray = <T>(size: number, input: T[]) => {
    return Array.from({length: size}, (_, index) => input[index % input.length])
}

export const generateColors = (n: number) => {
    let colors: string[] = [];
    let prevHue: number | undefined = undefined;
    for (let i = 0; i < n; i++) {
        let color = generateColor(prevHue);
        prevHue = color.hue;
        colors.push(color.hex);
    }
    return colors;
}

export const generateColor = (prevHue?: number): {hex: string, hue: number} => {
    const min = 0.5;
    const max = 0.7;

    let hue = Math.random();

    if (prevHue !== undefined) {
        let diff: number;
        while (true) {
            diff = circularDistance(prevHue, hue, 1);
            if (diff > 0.2) {
                break;
            }
            hue = Math.random();
        }
    }

    return {hex: hsvToHex(hue, Math.random() * (max - min) + min, 1), hue};

}

export const getHueFromHex = (hex: string)  => {
    let rgb = hexToRgb(hex);
    let hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
    return hsl.h;
}


const toHex = (c: number) => {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
};

const hsvToHex = (h: number, s: number, v: number) => {
    // Konwersja HSV do RGB
    let rgb = hsvToRgb(h, s, v);

    // Konwersja RGB do hex


    return toHex(rgb.r) + toHex(rgb.g) + toHex(rgb.b);
}

const hsvToRgb = (h: number, s: number, v: number): HSV => {
    let r: number, g: number, b: number;
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    switch (i % 6) {
        case 0: r = v; g = t; b = p; break;
        case 1: r = q; g = v; b = p; break;
        case 2: r = p; g = v; b = t; break;
        case 3: r = p; g = q; b = v; break;
        case 4: r = t; g = p; b = v; break;
        case 5: r = v; g = p; b = q; break;
    }

    return {
        r: Math.round(r! * 255),
        g: Math.round(g! * 255),
        b: Math.round(b! * 255)
    };
}

const circularDistance = (a: number, b: number, max: number = 1) => {
    a -=  Math.floor(a / max) * max;
    b -=  Math.floor(b / max) * max;

    const directDistance = Math.abs(a - b);
    const boundaryDistance = max - Math.max(a, b) + Math.min(a, b);

    return Math.min(directDistance, boundaryDistance);
}

function hexToRgb(hex: string) {
    // Remove the hash if it's there
    hex = hex.replace(/^#/, '');

    // Parse the hex string
    let bigint = parseInt(hex, 16);

    // Extract the RGB components
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return {r, g, b};
}

function rgbToHsl(r: number, g: number, b: number) {
    // Normalize RGB values to be between 0 and 1
    r = r / 255;
    g = g / 255;
    b = b / 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h;
    let s;
    let l = (max + min) / 2;

    if (max === min) {
        h = 0;
        s = 0; // achromatic
    } else {
        let d = max - min;
        if (l > 0.5) {
            s = d / (2 - max - min);
        } else {
            s = d / (max + min);
        }

        if (max === r) {
            h = (g - b) / d + (g < b ? 6 : 0);
        } else if (max === g) {
            h = (b - r) / d + 2;
        } else if (max === b) {
            h = (r - g) / d + 4;
        }
        // @ts-ignore
        h = h / 6;
    }

    // Return in degrees for hue, and percentages for saturation and lightness
    return {h: h * 360, s: s * 100, l: l * 100};
}
