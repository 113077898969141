import { Input } from "antd";
import { observer } from "mobx-react";
import React from "react";

import "./components/fonts.css";
import { AppViewModel } from "./AppViewModel";
import './App.css';
import { AppHeader } from "./components/AppHeader/AppHeader";
import { ChildTitleScreen } from "./components/ChildState/ChildTitleScreen";
import { StatusMessage } from "./components/ChildState/shared/StatusMessage";
import { Section } from "./components/Components/Section";
import { GameScreen } from "./components/GameScreen";
import { authStore } from "./model/auth";
import { ConnectionState, Screen } from "./model/state";
import { WS } from "./model/websocket";
import { parseString, PartType } from "./syllabes/algorithm";
import { testResults } from "./syllabes/testCases";
import "./font-faces-generated.css";

interface IAppProps {
    vm: AppViewModel;
}

// Quick style for the connection info banner
const connectionInfoStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '4px 10px',
    fontSize: '12px',
    zIndex: 1000,
    textAlign: 'center',
    fontFamily: 'monospace'
};

// Style for direct mode label
const directModeStyle: React.CSSProperties = {
    backgroundColor: '#1890ff',
    color: 'white',
    padding: '2px 6px',
    borderRadius: '4px',
    marginRight: '10px',
    fontWeight: 'bold'
};

@observer
export class App extends React.Component<IAppProps> {
    private _vm: AppViewModel;

    constructor(props: any) {
        super(props);
        this._vm = props.vm;
    }



    private renderContent() {
        if (WS.isDirectMode) {
            switch (this._vm.connectionState) {
                case ConnectionState.WSNotConnected:
                    return (
                        <StatusMessage title="Utracono połączenie">
                            <p>Utracono połączenie z aplikacją. Upewnij się, że aplikacja SY-LA-BY na drugim urządzeniu działa oraz, że urządzenie to jest wciąż podłączone do lokalnej sieci.</p>
                            <p>W razie problemów, zapoznaj się z <a href="https://">dokumentacją</a> na temat konfiguracji bezpośredniego połączenia. Jeśli problem nadal występuje i oba urządzenia mają dostęp dp internetu, sugerujemy połączyć się za pomocą naszego serwera <a href="https://sy-la-by.pl/">Tutaj</a> </p>
                            <p>Kod błędu: 0x6A00</p>
                        </StatusMessage>
                    );
                case ConnectionState.WSConnected:
                    return (
                        <StatusMessage>Loading spinner goes here</StatusMessage>
                    );
                case ConnectionState.ChildConnected:
                    return this.renderChildState();
                case ConnectionState.ServerConnected:
                default:
                    return (
                        <StatusMessage title="Nieoczekiwany błąd">
                            <p>Wystąpił nieoczekiwany błąd. Odśwież stronę, a jeśli problem nadal występuje i oba urządzenia mają dostęp dp internetu, sugerujemy połączyć się za pomocą naszego serwera <a href="https://sy-la-by.pl/">Tutaj</a></p>
                            <p>Kod błędu: 0x7C00 ({this._vm.connectionState})</p>
                        </StatusMessage>
                    );


            }
        } else {
            if (authStore.isUnauthenticated) {
                return (
                    <StatusMessage title="Wymagane logowanie">
                        <p>Aby sterować aplikacją SY-LA-BY na innym urządzeniu za pomocą naszego serwera należy się zalogować.</p>
                        <p>Istnieje również opcja bezpośredniego połączenia jeśli oba urządzenia są w tej samej sieci lokalnej. Więcej na ten temat znajdziesz w <a href="https://">dokumentacji</a>.</p>
                    </StatusMessage>
                );
            }

            switch (this._vm.connectionState) {
                case ConnectionState.WSNotConnected:
                    return (
                        <StatusMessage title="Łączenie...">
                            <p>Łączenie z serwerem...</p>
                        </StatusMessage>
                    );
                case ConnectionState.WSConnected:
                    return (
                        <StatusMessage title="Łączenie...">
                            <p>Rejestracja klienta...</p>
                        </StatusMessage>
                    );
                case ConnectionState.ServerConnected:
                    return (
                        <StatusMessage title="Połączono">
                            <p>Połączono z serwerem. Uruchom aplikację SY-LA-BY na drugim urządzeniu oraz zaloguj się.</p>
                        </StatusMessage>
                    );
                case ConnectionState.ChildConnected:
                    return this.renderChildState();

            }
        }
    }

    private renderChildState() {
        switch (this._vm.state.currentScreen) {
            case Screen.Title:
                return <ChildTitleScreen state={this._vm.state} onStartGameClick={this._vm.onStartGameClick} />
            case Screen.Game:
                return <GameScreen vm={this._vm} />;
            case Screen.Unknown:
            default:
                return (
                    <StatusMessage title="Wystąpił błąd">
                        <p>Wygląda na to, że aplikacja na drugim urządzeniu jest w złym stanie. Jeśli to możliwe spróbuj ją zrestartować.</p>
                        <p>Jeśli problem będzie się powtarzać skontaktuj się z naszą pomocą techniczną.</p>
                    </StatusMessage>
                );

        }
    }

    public render() {
        return (
            <div className="app">
                <AppHeader
                    toggleDev={this._vm.toggleDev}
                    state={this._vm.state}
                />
                <div className="content">
                    {this.renderContent()}

                </div>

                {this._vm.dev && <>
                <Input onChange={this._vm.onTestSentenceChange} value={this._vm.testSentence}/>

                <h3>
                    {parseString(this._vm.testSentence).reduce((acc, part) => {
                        if (part.type === PartType.Word) {
                            part.result.syl.forEach((syl, i) => {
                                acc.result.push(<span
                                    style={{color: acc.lastColor % 2 ? '#ff4de3' : '#ff3c3c'}}>{syl}</span>);
                                acc.lastColor++;
                                if (i !== part.result.syl.length - 1) {
                                    acc.result.push(<span>-</span>);
                                }
                            })

                        } else if (part.type === PartType.Space) {
                            acc.result.push(<span>{part.value}</span>);
                        } else if (part.type === PartType.Other) {
                            acc.result.push(<span>{part.value}</span>);
                        }

                        return acc;
                    }, {result: [], lastColor: 0} as { result: JSX.Element[], lastColor: number }).result
                    }


                </h3>

                <Section title={"test"}>content?</Section>


                <Section>content?</Section>
                {/*<pre>{JSON.stringify(parseString(this._vm.testSentence), null, 2)}</pre>*/}

                <h3>TEST CASES</h3>

                {testResults().map((tr, i) => {
                    const {syl, valid, options} = tr;
                    const color = valid ? 'black' : 'red';
                    return (
                        <div key={i}>
                            <span key={i} style={{color}}>{syl.syl.join("-")}   </span>
                            {/*<span>[{options.toString()}] </span>
                            <span>[{syl.ph.toString()}] </span>
                            <span>[{syl.cs.toString()}] </span>
                            <span>[{syl.fs.toString()}] </span>*/}

                            {!valid && <span>[{options.toString()}] </span>}
                            {!valid && <span>[{syl.ph.toString()}] </span>}
                            {!valid && <span>[{syl.cs.toString()}] </span>}
                            {!valid && <span>[{syl.fs.toString()}] </span>}
                        </div>
                    )
                })}
                </>}

                {/* Connection info banner to display WebSocket and API URLs */}
                <div style={connectionInfoStyle}>
                    {WS.isDirectMode && (
                        <span style={directModeStyle}>DIRECT MODE</span>
                    )}
                    API: {authStore.baseURL} | WebSocket: {WS.currentWsUrl}
                </div>
            </div>
        )
    }
}
