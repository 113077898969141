import { DeleteTwoTone } from '@ant-design/icons';
import classNames from "classnames";
import { observer } from "mobx-react";
import React from 'react';
import AnimateHeight from "react-animate-height";
import { HexColorPicker } from "react-colorful";
import "./ColorPicker.css";

const colors = [
    ["#b71c1c", "#d32f2f", "#f44336", "#e57373", "#ffcdd2"], // 1
    ["#880e4f", "#c2185b", "#e91e63", "#f06292", "#f8bbd0"], // 2
    ["#4a148c", "#7b1fa2", "#9c27b0", "#ba68c8", "#e1bee7"], // 3
    ["#0d47a1", "#1976d2", "#2196f3", "#64b5f6", "#bbdefb"], // 6
    ["#006064", "#0097a7", "#00bcd4", "#4dd0e1", "#b2ebf2"], // 8
    ["#33691e", "#689f38", "#8bc34a", "#aed581", "#dcedc8"], // 11
    ["#f57f17", "#fbc02d", "#ffeb3b", "#fff176", "#fff9c4"], // 13
    ["#bf360c", "#e64a19", "#ff5722", "#ff8a65", "#ffccbc"], // 16
    ["#000000", "#525252", "#969696", "#D9D9D9", "#FFFFFF"]
];// 19

export interface IColorPickerProps {
    color: string;
    onChange: (color: string) => void;
    onClick: () => void;
    canRemove: boolean;
    onRemoveClick: () => void;
    popoverVisible: boolean;
    containerRef?: React.RefObject<HTMLDivElement>;
}

interface IColorPickerState {
    offset: number;
    topPosition: number;
}

@observer
export class ColorPicker extends React.Component<IColorPickerProps, IColorPickerState> {
    private _popoverRef?: HTMLDivElement;

    constructor(props: IColorPickerProps) {
        super(props);

        this.state = {
            offset: 0,
            topPosition: 0
        }

        window.addEventListener("resize", this.calculatePopOverPosition);
    }

    public componentDidUpdate(prevProps: Readonly<IColorPickerProps>) {
        if (!prevProps.popoverVisible && this.props.popoverVisible) {
            if (this._popoverRef) {
                this.calculatePopOverPosition();
            }
        }
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.calculatePopOverPosition);
    }

    public render() {

        return (
            <div className="color-picker">
                <div onClick={this.props.onClick}
                     className={classNames("color-picker-swatch", {"color-picker-swatch-active": this.props.popoverVisible})}
                     style={{backgroundColor: "#" + this.props.color}}></div>
                <div className={classNames("color-picker-popover", {"color-picker-popover-fixed": !!this.props.containerRef})}
                     ref={this.popoverRef}>
                    <AnimateHeight duration={0} height={this.props.popoverVisible ? 'auto' : 0}>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <div style={{width: '120px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                {colors.map((p, i) => {
                                    return <div key={i} style={{display: 'flex'}}>
                                        {p.map(c => {
                                            return <div key={c} onClick={() => this.props.onChange(c)} style={{cursor: 'pointer', width: '20px', height: '20px', backgroundColor: c, border: '1px solid #333'}}></div>
                                        })}
                                    </div>
                                })}
                            </div>
                            <div>
                                <HexColorPicker color={this.props.color} onChange={this.props.onChange}/>
                                {this.props.canRemove &&
                                    <div className="color-picker-remove" onClick={this.props.onRemoveClick}>
                                        <DeleteTwoTone/> Usuń</div>}
                            </div>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }

    private popoverRef = (ref: HTMLDivElement) => {
        this._popoverRef = ref;
        this.calculatePopOverPosition();
    }

    private calculatePopOverPosition = () => {
        if (!this._popoverRef) {
            return;
        }

        const width = 330;
        const leftPos = this._popoverRef.getBoundingClientRect().left;

        // Calculate horizontal offset
        this.setState({
            offset: window.innerWidth / 2 - width / 2
        });

        // Calculate vertical position if container ref is provided
        if (this.props.containerRef && this.props.containerRef.current) {
            const containerRect = this.props.containerRef.current.getBoundingClientRect();
            const topPosition = containerRect.bottom + 10; // 10px below the container

            this.setState({ topPosition });
        }
    }
}
