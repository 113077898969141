import { Button } from 'antd';
import { observer } from "mobx-react";
import React from 'react';

import './ChildTitleScreen.css';
import { IAppState } from "../../model/state";

interface IChildTitleScreenProps {
    state: IAppState
    onStartGameClick: () => void;
}

@observer
export class ChildTitleScreen extends React.Component<IChildTitleScreenProps, any> {
    public render() {
        return (
            <div>
                <div className="title">TITLE SCREEN</div>
                <Button type={"primary"} danger onClick={this.props.onStartGameClick}>Start game</Button>
            </div>
        )
    }
}
