
export enum Action {
    ForceSplit,
    ForceSplitAfterJoin,
    ForceSplitBeforeJoin,
    Join
}

export interface ICondition {
    prev?: string;
    prevLength?: number;
    next?: string;
    nextLength?: number;
    action?: Action,
}

interface IJoin {
    value: string;

    conditions: ICondition[];
}

export type Join = string | IJoin;

export const S = ['a', 'ą', 'e', 'ę', 'i', 'o', 'ó', 'u', 'y'];
export const SFirstLetterStandalone = ['a', 'o', 'u'];
export const endSyllabe = ['ą', 'ę', 'ń'];
export const SPRegex =  `(q|w|r|t|p|s|ś|d|f|g|h|j|k|l|ł|z|ż|ź|x|c|ć|v|b|n|ń|m){1}`;
export const EmptyRegex = '^$';
export const LettersRegexpString = 'a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ';
export const LetterRegexp = new RegExp(`[${LettersRegexpString}]`);
export const WordRegexp = new RegExp(`^[${LettersRegexpString}]+$`);
export const SpaceRegexp = /^\s+$/;

export const joins: Join[] = [
    'ye',
    'yo',
    {
        value: 'au',
        conditions: [
            { prev: 'n', next: 'k', action: Action.ForceSplit }, // nauka
            { prev: 'p', next: 'l', action: Action.ForceSplit }, // Paulina
            { prev: 'z', action: Action.ForceSplit }, // zauważyć
            { action: Action.Join }

        ]
    },
    {
        value: 'ue',
        conditions: [ { prev: 'p' } ] // puenta
    },
    {
        value: 'rz',
        conditions: [ // zamarzyć
            { prev: '^(?!ko).*ma', next: 'ać', action: Action.ForceSplit }, // przekoma-rzać, zamar-zać
            { prev: 'ma', next: '[nl]', nextLength: 1,  action: Action.ForceSplit }, // zamar-znąć, zmar-zliśmy
            { action: Action.Join }
        ]
    },
    'cz',
    {
        value: 'dz',
        conditions: [ { prev: '^(?!na)' } ] // nadzorca
    },
    'dż',
    'dź',
    'cj',
    {
        value: 'pl',
        conditions: [ { prev: 'a', next: 'i' } ]
    },
    'gr',
    'eń',
    'sł',
    'ść',
    'yń',
    'ań',
    'eń',
    'gn',
    'kł',
    {
        value: 'za',
        conditions: [ { next: `^[pt]${SPRegex}`, nextLength: 2, action: Action.ForceSplitAfterJoin} ]
    },
    {
        value: 'tr',
        conditions: [ { next: '[^z]' } ]
    },
    {
        value: 'nt',
        conditions: [ { action: Action.ForceSplit } ]
    },
    {
        value: 'dk',
        conditions: [ { action: Action.ForceSplit } ]
    },
    {
        value: 'oj',
        conditions: [ { prev: EmptyRegex, next: `^${SPRegex}`, nextLength: 1 } ] // oj-ciec
    },
    {
        value: 'at',
        conditions: [ { prev: EmptyRegex, next: `^${SPRegex}`, nextLength: 1 } ] // at-las, a-to-po-we
    },
    {
        value: 'al',
        conditions: [ { prev: EmptyRegex, next: `^${SPRegex}`, nextLength: 1 } ] // al-go-rytm
    },
    {
        value: 'ab',
        conditions: [ { prev: EmptyRegex, next: `^${SPRegex}`, nextLength: 1 } ]
    },
    {
        value: 'rw',
        conditions: [ { next: `^${SPRegex}`, nextLength: 1 } ]
    },
    {
        value: 'ar',
        conditions: [
            { prev: EmptyRegex, next: `^${SPRegex}`, nextLength: 1 },
            { prev: '', next: 'dz' }
        ]
    },
    {
        value: 'od',
        conditions: [
            { prev: EmptyRegex, next: `^${SPRegex}`, nextLength: 1, action: Action.ForceSplitAfterJoin } // od-bior-nik, o-de-słać
        ]
    },
    {
        value: 'rn',
        conditions: [ { next: `^${SPRegex}`, nextLength: 1, action: Action.ForceSplitAfterJoin }]
    },
    'dźw',
    'bio',
    'kry',
    {
        value: 'ski',
        conditions: [ { next: EmptyRegex, action: Action.ForceSplitBeforeJoin } ]
    },
    {
        value: 'pod',
        conditions: [ { next: 'eks' } ]
    },
    {
        value: 'eks',
        conditions: [ { next: '[^j]' } ]
    },
    {
        value: 'nie',
        conditions: [ { prev: EmptyRegex, action: Action.ForceSplitAfterJoin } ]
    },
    'gło',
    {
        value: 'sto',
        conditions: [ { prev: 'hi' } ]
    },
    'kwi',
    'pro',
    'glą',
    {
        value: 'naj',
        conditions: [ { next: `^${SPRegex}`, nextLength: 1 } ]
    },

    'dzie',
    {
        value: 'prze',
        conditions: [ { prev: EmptyRegex, next: '[^d]', action: Action.ForceSplitAfterJoin } ] // prze-stra-szyć, przed-się-bior-czoś-ci
    },
    {
        value: 'przy',
        conditions: [ { prev: EmptyRegex, action: Action.ForceSplitAfterJoin } ] // przy-szła
    },
    {
        value: 'bez',
        conditions: [ { next : `^${SPRegex}`, nextLength: 1, action: Action.ForceSplitAfterJoin } ]
    },
    'twór',
    {
        value: 'plan',
        conditions: [ { next: EmptyRegex }]
    },
    'wnętrz',
    {
        value: 'eu',
        conditions: [ { next: 's$', action: Action.ForceSplit }] // ?? kos-ma-te-us ?
    },
    'ou',
    'ia',
    'ią',
    'ie',
    'ię',
    'ii',
    'io',
    'ió',
    'iu',
    'iy',
    'ch',
    'sz',
]