import { action, makeObservable, observable } from "mobx";
import { ChangeEvent } from "react";
import { ColorMode, ColorScheme, IGameState, SentenceCase, SentenceSplit } from "../../../model/state";
import { parseString, PartType } from "../../../syllabes/algorithm";
import {
    firstDiffIndex,
    getWordsBoundaries,
    hasWordsChanged,
    lastDiffIndex,
    splitAtIndexes
} from "../../../syllabes/utils";

interface INewSentenceViewModelParams {
    onSubmitSentence: (sentence: string, splits: number[], font: string, colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[], sentenceCase: SentenceCase, sentenceSplit: SentenceSplit, background: string) => void;
    gameState: IGameState;
}

export class NewSentenceViewModel {
    private _params: INewSentenceViewModelParams;

    @observable
    public inputValue: string = "";

    @observable
    public inputValue2: string = "";

    @observable
    public autoSplit: boolean = true;

    @observable
    public parsedSentence: string = "";

    @observable
    public splits: number[] = [];

    @observable
    public font: string = "";

    @observable
    public colorMode: ColorMode = ColorMode.Word;

    @observable
    public colorScheme: ColorScheme = ColorScheme.Random;

    @observable
    public colors: string[] = [];

    @observable
    public userColors: string[] = [];

    @observable
    public initialStateApplied: boolean = false;

    @observable
    public sentenceCase: SentenceCase = SentenceCase.Original;

    @observable
    public sentenceSplit: SentenceSplit = SentenceSplit.Show;

    @observable
    public background: string = "";

    constructor(params: INewSentenceViewModelParams) {
        makeObservable(this);
        this._params = params;

        // We don't initialize settings from gameState here
        // This will be handled by the NewSentence component when initialStateReceived is true
    }

    @action
    public updateParams = (params: INewSentenceViewModelParams) => {
        console.log("Updating NewSentenceViewModel params");
        this._params = params;
    }

    @action
    public updateSettingsFromGameState = (gameState: IGameState) => {
        console.log("Updating settings from game state:", gameState);
        this.font = gameState.font;
        this.colorMode = gameState.colorMode;
        this.colorScheme = gameState.colorScheme;
        this.colors = [...gameState.colors];
        this.userColors = [...gameState.userColors];
        this.sentenceCase = gameState.sentenceCase;
        this.sentenceSplit = gameState.sentenceSplit;
        this.background = gameState.background;
        this.initialStateApplied = true;

        console.log("Updated settings from gameState:", {
            font: this.font,
            colorMode: this.colorMode,
            colorScheme: this.colorScheme,
            colors: this.colors,
            userColors: this.userColors,
            sentenceCase: this.sentenceCase,
            sentenceSplit: this.sentenceSplit,
            initialStateApplied: this.initialStateApplied
        });
    }

    @action
    public setPreviewSettings = (
        font: string,
        colorMode: ColorMode,
        colorScheme: ColorScheme,
        colors: string[],
        userColors: string[],
        sentenceCase: SentenceCase,
        sentenceSplit: SentenceSplit,
        background: string
    ) => {
        this.font = font;
        this.colorMode = colorMode;
        this.colorScheme = colorScheme;
        this.colors = colors;
        this.userColors = userColors;
        this.sentenceCase = sentenceCase;
        this.sentenceSplit = sentenceSplit;
        this.background = background;
    }

    @action
    public onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;

        if (this.autoSplit) {
            this.parseAutoSplitResult(newValue);
        } else {
            // new character(s) were added at the end of the string,
            // no action needed
            if (!newValue.startsWith(this.inputValue)) {
                this.handleInputModification(newValue);
            }
        }

        this.inputValue = e.currentTarget.value;
    }

    @action
    public onInputChange2 = (e: ChangeEvent<HTMLInputElement>) => {
        this.inputValue2 = e.currentTarget.value;
    }

    @action
    public onSubmitClick = () => {
        this._params.onSubmitSentence(
            this.inputValue,
            this.splits,
            this.font,
            this.colorMode,
            this.colorScheme,
            this.colors,
            this.userColors,
            this.sentenceCase,
            this.sentenceSplit,
            this.background
        );
    }

    @action
    public onAutoSplitChange = () => {
        this.autoSplit = !this.autoSplit;
        if (this.autoSplit) {
            this.parseAutoSplitResult(this.inputValue);
        }
    }

    @action
    public toggleSplit = (index: number) => {
        console.log("toggle split", index);

        if (this.autoSplit) {
            this.autoSplit = false;
        }

        if (this.splits.includes(index)) {
            this.splits = this.splits.filter(s => s !== index);
        } else {
            this.splits.push(index);
            this.splits.sort((a, b) => a - b);
        }
    }

    private getSplittedSentence = (): string => {
        return splitAtIndexes(this.inputValue, this.splits).join("-");
    }

    private parseAutoSplitResult = (str: string) => {
        const result = parseString(str);
        const splits: number[] = [];

        let count = 0;
        result.forEach(part => {
            if (part.type === PartType.Word) {
                const syls = part.result.syl;
                if (syls.length > 1) {
                    syls.forEach((syl, i) => {
                        count += syl.length;
                        if (i !== syls.length - 1) {
                            splits.push(count - 1);
                        }
                    })
                } else {
                    count += syls[0].length;
                }
            } else {
                count += part.value.length;
            }
        });

        this.splits = splits;
    }

    /**
     * Dobra to trzeba opisać bo sam zapomnę za miesiąc
     *
     * Ogolnie chcemy, zeby po modyfikacji inputu *w srodku* i przy *wylaczonym* auto splicie
     * usunal istniejace podzialy z modyfikowanego slowa ale zostawil reszte podzialow ze slow
     * ktore nie sa zmienione w zaden sposob
     * @param newValue
     */
    private handleInputModification = (newValue: string) => {
        const firstDiff = firstDiffIndex(this.inputValue, newValue);
        const lastDiff = lastDiffIndex(this.inputValue, newValue);
        const boundaries = getWordsBoundaries(this.inputValue);
        const diffLength = newValue.length - this.inputValue.length;

        if (boundaries.length) {
            // sprawdzamy ze rzeczywiscie jakeis slowa sie zmienily, bo rownie dobrze
            // moglby byc dodane po prostu znaki interpunkcyjne
            const wordsChanged = hasWordsChanged(this.inputValue, newValue);

            // character(s) added at the beginning of the string
            if (lastDiff === undefined) {
                // it starts with word
                if (wordsChanged && boundaries[0][0] === 0) {
                    const endBoundary = boundaries[0][1];
                    this.splits = this.splits.filter(s => {
                        return !(s >= 0 && s <= endBoundary);
                    });

                }
                // splity zawsze przesuwamy nawet jesli nie trzeba bylo nic usuwac
                this.splits = this.splits.map(s => s + diffLength);

            } else if (firstDiff !== undefined) {
                if (wordsChanged) {
                    boundaries.forEach(b => {
                        const [bStart, bEnd] = b;
                        if (bEnd >= firstDiff && bStart <= lastDiff) {
                            this.splits = this.splits.filter(s => {
                                return !(s >= bStart && s <= bEnd);
                            });
                        }
                    });
                }
                // splity zawsze przesuwamy, nawet jesli nie trzeba bylo nic usuwac
                this.splits = this.splits.map(s => s > lastDiff ? s + diffLength : s);
            }
        }
    }
}
