import { message } from "antd";
import { action, makeAutoObservable } from 'mobx';
import { OnAuthResult, OnLogout } from "./messageBus";
import { OutgoingType } from './messageTypes';
import { WS } from './websocket';
import axios from 'axios';

// Auth types (moved from types/auth.ts)
export interface User {
  id: string;
  email: string;
  name: string | null;
  avatar: string | null;
}

export interface LoginResponse {
  user: User;
  accessToken: string;
  refreshToken: string;
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface RegisterCredentials extends LoginCredentials {
  name: string;
}

// Auth result interface for handling authentication results from WebSocket
export interface IAuthResult {
  success: boolean;
  message?: string;
  user?: User;
}

// Authentication states enum for better state management
export enum AuthState {
  Initializing = 'initializing',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  TokenRefreshing = 'tokenRefreshing'
}

// Constants
const BASE_URL = process.env.REACT_APP_API_BASE_URL!;
const AUTH_API_URL = `${BASE_URL}/auth`;

/**
 * Creates and configures an Axios instance with the given base URL
 */
function createApiClient(baseURL: string) {
  return axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

// API clients
const authApi = createApiClient(AUTH_API_URL);
const emailApi = createApiClient(BASE_URL);

/**
 * Updates API clients with the current auth token
 */
function updateAuthHeader(token: string | null) {
  const authHeader = token ? `Bearer ${token}` : '';
  authApi.defaults.headers.common['Authorization'] = authHeader;
  emailApi.defaults.headers.common['Authorization'] = authHeader;
}

// TODO: This class has too many responsibilities (auth state, API calls, UI modals, etc.)
// Consider refactoring into smaller, more focused classes
export class Auth {
  // User and token state
  public user: User | null = null;
  public accessToken: string | null = null;
  public refreshToken: string | null = null;

  // UI state
  public isLoginModalOpen = false;
  public isSignupModalOpen = false;

  // Application state
  private authState: AuthState = AuthState.Initializing;

  constructor() {
    makeAutoObservable(this);

    // Initialize authentication state
    this.initializeAuth();

    // Set up message bus subscriptions
    OnAuthResult.subscribe(this.onAuthResult);
    OnLogout.subscribe(this.handleLogout);
  }

  /**
   * Centralized method to update the auth state
   */
  @action
  private setAuthState(newState: AuthState): void {
    this.authState = newState;
  }

  // Public getters for auth state
  get isInitialized(): boolean {
    return this.authState !== AuthState.Initializing;
  }

  get isAuthenticated(): boolean {
    return this.authState === AuthState.Authenticated;
  }

  get isLoading(): boolean {
    return this.authState === AuthState.Initializing || this.authState === AuthState.TokenRefreshing;
  }

  get isUnauthenticated() {
    return this.authState === AuthState.Unauthenticated;
  }

  get refreshAttempted(): boolean {
    return this.authState !== AuthState.Initializing;
  }

  get isDirectMode(): boolean {
    return WS.isDirectMode;
  }

  // API-related getters
  get baseURL(): string | undefined {
    return authApi.defaults.baseURL;
  }

  /**
   * Initialize authentication state based on current mode
   */
  private async initializeAuth(): Promise<void> {
    try {
      // For remote mode, try to refresh the token
      if (!this.isDirectMode) {
        try {
          await this.refresh();
          // onRemoteLoginSuccess already sets the state to authenticated
        } catch (error) {
          console.log('Error refreshing token', error);
          this.setAuthState(AuthState.Unauthenticated);
        }
      } else {
        // In direct mode, just mark as initialized
        this.setAuthState(AuthState.Unauthenticated);
      }
    } catch (error) {
      console.error('Auth initialization failed:', error);
      this.handleLogout();
    }
  }

  /**
   * Handles authentication result from WebSocket
   */
  private onAuthResult = (authResult: IAuthResult): void => {
    if (authResult.success && authResult.user) {
      this.user = authResult.user;
      this.setAuthState(AuthState.Authenticated);
      // TODO: UI concerns mixed with auth logic - consider separating these responsibilities
      this.hideLoginModal();
      void message.success('Successfully logged in!');
    } else {
      // Failed authentication
      this.setAuthState(AuthState.Unauthenticated);
      void message.error(authResult.message || 'Authentication failed');
    }
  }

  /**
   * Handles successful login/authentication from remote API
   */
  public onRemoteLoginSuccess = (response: LoginResponse): void => {
    this.user = response.user;
    this.accessToken = response.accessToken;
    this.refreshToken = response.refreshToken;
    updateAuthHeader(response.accessToken);
    this.setAuthState(AuthState.Authenticated);

    // For remote mode, establish WebSocket connection after authentication
    if (!this.isDirectMode && this.refreshToken) {
      WS.createAuthenticatedConnection(this.refreshToken);
    }
  };

  /**
   * Handles logout from any source
   */
  private handleLogout = (): void => {
    // Clear user data and tokens
    this.user = null;
    this.accessToken = null;
    this.refreshToken = null;
    updateAuthHeader(null);
    this.setAuthState(AuthState.Unauthenticated);

    // In remote mode, disconnect the WebSocket
    if (!this.isDirectMode) {
      WS.disconnect();
    }
  };

  // API METHODS

  /**
   * Logs user in with provided credentials
   */
  public async login(credentials: LoginCredentials): Promise<LoginResponse> {
    try {
      if (this.isDirectMode) {
        return await this.loginDirect(credentials);
      }

      const { data } = await authApi.post<LoginResponse>('/login', credentials);
      this.onRemoteLoginSuccess(data);
      return data;
    } catch (error) {
      this.setAuthState(AuthState.Unauthenticated);
      throw error;
    }
  }

  /**
   * Registers a new user
   */
  public async register(credentials: RegisterCredentials): Promise<LoginResponse> {
    try {
      const { data } = await authApi.post<LoginResponse>('/register', credentials);
      this.onRemoteLoginSuccess(data);
      return data;
    } catch (error) {
      this.setAuthState(AuthState.Unauthenticated);
      throw error;
    }
  }

  /**
   * Refreshes the authentication token
   */
  public async refresh(): Promise<LoginResponse> {
    if (this.authState === AuthState.TokenRefreshing) {
      throw new Error('Token refresh already in progress');
    }

    this.setAuthState(AuthState.TokenRefreshing);

    try {
      const { data } = await authApi.post<LoginResponse>('/refresh');
      this.onRemoteLoginSuccess(data);
      return data;
    } catch (error) {
      this.setAuthState(AuthState.Unauthenticated);
      throw error;
    }
  }

  /**
   * Performs direct WebSocket login in direct mode
   */
  public loginDirect(credentials: LoginCredentials): Promise<LoginResponse> {
    return new Promise<LoginResponse>((resolve, reject) => {
      // Create one-time subscription to handle auth result
      const authSub = OnAuthResult.subscribe((authResult) => {
        // Unsubscribe to avoid memory leaks
        authSub.unsubscribe();

        if (authResult.success && authResult.user) {
          // Create a compliant LoginResponse from the auth result
          const response: LoginResponse = {
            user: authResult.user,
            accessToken: '',  // Direct mode doesn't use these tokens
            refreshToken: ''
          };
          resolve(response);
        } else {
          reject(new Error(authResult.message || 'Authentication failed'));
        }
      });

      // Send authentication request through WebSocket
      WS.send({
        type: OutgoingType.DIRECT_LOGIN, // E_1
        email: credentials.email,
        password: credentials.password
      });
    });
  }

  /**
   * Verifies a user's email with the provided token
   */
  public async verifyEmail(token: string): Promise<void> {
    await emailApi.get(`/email/verify/${token}`);
  }

  /**
   * Logs the user out
   */
  public logout = async (): Promise<void> => {
    try {
      if (this.isDirectMode) {
        // In direct mode, send logout notification through WebSocket
        WS.send({
          type: OutgoingType.DIRECT_LOGOUT
        } as any);
      } else {
        // In remote mode, handle logout via HTTP API
        await authApi.post('/logout').catch(err =>
          console.error('Error during logout API call:', err)
        );
      }
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      this.handleLogout();
    }
  };

  // MODAL MANAGEMENT METHODS

  /**
   * Shows the login modal
   */
  public showLoginModal = (): void => {
    this.isLoginModalOpen = true;
    this.isSignupModalOpen = false;
  };

  /**
   * Hides the login modal
   */
  public hideLoginModal = (): void => {
    this.isLoginModalOpen = false;
  };

  /**
   * Shows the signup modal
   */
  public showSignupModal = (): void => {
    this.isSignupModalOpen = true;
    this.isLoginModalOpen = false;
  };

  /**
   * Hides the signup modal
   */
  public hideSignupModal = (): void => {
    this.isSignupModalOpen = false;
  };
}

// Singleton instance
export const authStore = new Auth();
