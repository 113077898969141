import { observer } from "mobx-react";
import React from 'react';
import { fontNames } from "../../font-faces-names";
import { IGameState } from "../../model/state";
import { SentencePreview } from "../ChildState/SentencePreview/SentencePreview";
import { SentenceMode } from "../ChildState/shared/SentenceEnums";

export interface IFontPickerProps {
    gameState: IGameState;
    onFontChange: (font: string) => void;
}

@observer
export class FontPicker extends React.Component<IFontPickerProps> {
    public render() {
        const {onFontChange, gameState} = this.props;
        return (
            <div style={{overflow: 'hidden', cursor: 'pointer', display: 'flex', flexDirection: 'column'}}>
                {fontNames.map(fontName => {
                    return (
                        <SentencePreview sentenceMode={SentenceMode.None}
                                         overrideFont={fontName}
                                         onSentenceClick={() => onFontChange(fontName)}
                                         singleLine
                                         key={fontName}
                                         {...gameState}
                        />
                    )
                })}
            </div>
        )
    }
}
