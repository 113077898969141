import { action, computed, makeObservable, observable } from "mobx";
import { ColorMode, ColorScheme, IGameState } from "../../../model/state";
import { generateColor, generateColors, getHueFromHex, numberOfColors } from "../../../model/utils";
import { IColorManagerProps } from "./ColorManager";

export class ColorManagerViewModel {
    @observable
    private _colorMode!: ColorMode;

    @observable
    private _colorScheme!: ColorScheme;

    @observable
    private _colors!: string[];

    @observable
    private _userColors!: string[];

    private onColorsChange: (colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[]) => void;
    private onColorSelectStart: (callback: () => void) => void;
    private onColorSelectEnd: () => void;

    constructor(props: IColorManagerProps) {
        this.updateState(props.gameState);
        this.onColorsChange = props.onColorsChange;
        this.onColorSelectStart = props.onColorSelectStart;
        this.onColorSelectEnd = props.onColorSelectEnd;
        makeObservable(this);
    }

    @action
    public updateState(gameState: IGameState) {
        this._colorMode = gameState.colorMode;
        this._colors = gameState.colors;
        this._colorScheme = gameState.colorScheme;
        this._userColors = gameState.userColors;
    }

    @computed
    public get colorMode() {
        return this._colorMode;
    }

    @computed
    public get colorScheme() {
        return this._colorScheme;
    }

    @computed
    public get colors() {
        return this._colors;
    }

    @computed
    public get userColors() {
        return this._userColors;
    }

    @action
    public setColorMode = (colorMode: ColorMode, gameState: IGameState) => {
        this._colorMode = colorMode;

        if (this._colorScheme == ColorScheme.Random) {
            const colorsNeeded = numberOfColors(gameState.sentence, gameState.splits, this.colorMode);
            if (this._colors.length < colorsNeeded) {
                this._colors = [...this._colors, ...generateColors(colorsNeeded - this._colors.length)];
            } else if (this._colors.length > colorsNeeded) {
                this._colors = this._colors.slice(0, colorsNeeded);
            }
        }

        this.onColorsChange(this.colorMode, this.colorScheme, this.colors, this.userColors);
    }

    @action
    public SetColorScheme = (colorScheme: ColorScheme, gameState: IGameState) => {
        if (colorScheme === ColorScheme.Random) {
            const colorsNeeded = numberOfColors(gameState.sentence, gameState.splits, this.colorMode);
            this._colors = generateColors(colorsNeeded);
        }

        this._colorScheme = colorScheme;
        this.onColorsChange(this.colorMode, this.colorScheme, this.colors, this.userColors);
    }

    @action
    public onRandomClick = (gameState: IGameState) => {
        this._colors = generateColors(numberOfColors(gameState.sentence, gameState.splits, this._colorMode));
        this.onColorsChange(this.colorMode, this.colorScheme, this.colors, this.userColors);
    }

    @observable
    private _activeColorPickerx: number | undefined;

    @action
    private _setActiveColorPicker = (picker: number | undefined) => {
        if (this._activeColorPickerx === undefined && picker !== undefined) {
            this.onColorSelectStart(this.onOverlayClick);
        } else if(this._activeColorPickerx !== undefined && picker === undefined) {
            this.onColorSelectEnd();
        }
        this._activeColorPickerx = picker;
    }

    @computed
    public get isOverlayVisible() {
        return this._activeColorPickerx !== undefined;
    }

    @computed
    public get activeColorPicker() {
        return this._activeColorPickerx;
    }

    @action
    public onOverlayClick = () => {
        this._setActiveColorPicker(undefined);
        this.onColorsChange(this.colorMode, this.colorScheme, this.colors, this.userColors);
    }

    @action
    public onColorPickerClick = (index: number) => {
        this._setActiveColorPicker(index === this._activeColorPickerx ? undefined : index);
    }

    @action
    public onColorPickerChange = (index: number, color: string) => {
        this._userColors[index] = color.substring(1);
    }

    @action
    public addColor = () => {
        this._userColors.push(generateColor(getHueFromHex(this._userColors[this._userColors.length - 1])).hex);
        setTimeout(() => {
            this._setActiveColorPicker(this._userColors.length - 1);

        })
        // this.onColorsChange(this.colorMode, this.colorScheme, this.colors, this.userColors);
    }

    @action removeColor = (i: number) => {
        if (i === this._userColors.length - 1) {
            this._setActiveColorPicker(i - 1);
        }
        this._userColors.splice(i, 1);

        this.onColorsChange(this.colorMode, this.colorScheme, this.colors, this.userColors);
    }

}
