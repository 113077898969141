import { observer } from "mobx-react";
import React from 'react';
import { AppViewModel } from "../AppViewModel";
import { ColorManagerProvider } from './ChildState/ColorManager/ColorManagerContext';
import { CombinedSentenceSection } from './ChildState/CombinedSentenceSection';
import "./GameScreen.css";

export interface IMainScreenProps {
    vm: AppViewModel;
}

@observer
export class GameScreen extends React.Component<IMainScreenProps> {
    public render() {
        const {vm} = this.props;
        const {
            state,
            onStartGameClick,
            onSentenceCaseToggle,
            onSentenceCaseChange,
            onSentenceSplitChange,
            onSentenceSplitToggle,
            onSelectionClick,
            onFontChange,
            onColorsChange,
            onBackgroundChange,
            onSubmitSentence,
            initialStateReceived,
            isWaitingForState
        } = vm;
        return (
            <ColorManagerProvider>
                <div className="main-screen-wrapper">
                    <div className="main-screen-content">
                        <CombinedSentenceSection
                            dev={vm.dev}
                            state={state}
                            vm={vm}
                            onStartGameClick={onStartGameClick}
                            onSentenceCaseToggle={onSentenceCaseToggle}
                            onSentenceCaseChange={onSentenceCaseChange}
                            onSentenceSplitToggle={onSentenceSplitToggle}
                            onSentenceSplitChange={onSentenceSplitChange}
                            onSelectionClick={onSelectionClick}
                            onFontChange={onFontChange}
                            onColorsChange={onColorsChange}
                            onBackgroundChange={onBackgroundChange}
                            gameState={state.gameState}
                            onSubmitSentence={onSubmitSentence}
                            initialStateReceived={initialStateReceived}
                            isWaitingForState={isWaitingForState}
                        />
                    </div>
                </div>
            </ColorManagerProvider>
        );
    }
}
