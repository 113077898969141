import { SendOutlined } from "@ant-design/icons";

import './NewSentence.css';
import { Button, Input, InputRef } from "antd";
import { observer } from "mobx-react";
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { AppViewModel } from "../../../AppViewModel";
import { ColorMode, ColorScheme, IGameState, SentenceCase, SentenceSplit } from "../../../model/state";
import { Toggle } from "../../Components/Toggle";
import { NewSentencePreview } from "./NewSentencePreview";
import { NewSentenceViewModel } from "./NewSentenceViewModel";

interface INewSentenceProps {
    onSubmitSentence: (sentence: string, splits: number[], font: string, colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[], sentenceCase: SentenceCase, sentenceSplit: SentenceSplit, background: string) => void;
    dev: boolean;
    gameState: IGameState;
    initialStateReceived?: boolean;
    isActive?: boolean;
    vm: AppViewModel;
}

const NewSentenceComponent: ForwardRefRenderFunction<NewSentenceClass, INewSentenceProps> = (props, ref) => {
    return <NewSentenceClass {...props} ref={ref} />;
};

@observer
export class NewSentenceClass extends React.Component<INewSentenceProps> {
    private _vm!: NewSentenceViewModel;
    private inputRef = React.createRef<InputRef>();

    constructor(props: INewSentenceProps) {
        super(props);
        if (!(window as any)._nsVM) {
            console.log("Creating new NewSentenceViewModel instance");
            (window as any)._nsVM = new NewSentenceViewModel(props);
        } else {
            console.log("Using existing NewSentenceViewModel instance, updating params");
            // Update the params of the existing ViewModel to ensure it has the latest props
            (window as any)._nsVM.updateParams(props);
        }
        this._vm = (window as any)._nsVM;

        // Only apply settings if we have received the initial state from the server
        // Architecture rule: We always receive complete state from the server
        console.log("NewSentence constructor - initialStateReceived:", props.initialStateReceived, "initialStateApplied:", this._vm.initialStateApplied);
        if (props.initialStateReceived && props.gameState && !this._vm.initialStateApplied) {
            console.log("Applying initial state to NewSentence in constructor");
            this._vm.updateSettingsFromGameState(props.gameState);
        }
    }

    componentDidMount() {
        // Focus the input if the component is initially active
        if (this.props.isActive && this.inputRef.current) {
            // Use setTimeout to ensure the focus happens after the component is fully mounted
            setTimeout(() => {
                if (this.inputRef.current) {
                    this.inputRef.current.focus();
                    console.log("Input focused on mount");
                }
            }, 100);
        }
    }

    componentDidUpdate(prevProps: INewSentenceProps) {
        // Only update settings on initial state received from server
        // Architecture rule: We always receive complete state from the server
        console.log("NewSentence componentDidUpdate - initialStateReceived:", this.props.initialStateReceived, "prevInitialStateReceived:", prevProps.initialStateReceived, "initialStateApplied:", this._vm.initialStateApplied);
        if (!this._vm.initialStateApplied &&
            this.props.initialStateReceived &&
            !prevProps.initialStateReceived &&
            this.props.gameState) {
            console.log("Applying initial state to NewSentence in componentDidUpdate");
            this._vm.updateSettingsFromGameState(this.props.gameState);
        }

        // Focus the input when the tab becomes active
        if (this.props.isActive && !prevProps.isActive) {
            // Use setTimeout to ensure the focus happens after the tab switch is complete
            setTimeout(() => {
                if (this.inputRef.current) {
                    this.inputRef.current.focus();
                    console.log("Input focused in componentDidUpdate");
                } else {
                    console.log("Input ref is null in componentDidUpdate");
                }
            }, 100);
        }
    }

    // Handle input focus to select all text
    private handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    }

    // Public method to focus the input
    public focusInput = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
            console.log("Input focused via method");
        } else {
            console.log("Input ref is null in focusInput method");
        }
    }

    public render() {
        const {
            inputValue,
            onInputChange,
            onSubmitClick,
            autoSplit,
            onAutoSplitChange,
            splits,
            toggleSplit,
            font,
            colorMode,
            colorScheme,
            colors,
            userColors,
            sentenceCase,
            sentenceSplit,
            background
        } = this._vm;

        return (
            <div className="new-sentence-wrapper">
                <Toggle label={"Automatycznie rozdzielaj sylaby"} checked={"Tak"} unChecked={"Nie"}
                        isChecked={autoSplit} onChange={onAutoSplitChange}/>

                <Input
                    style={{textAlign: 'center'}}
                    onChange={onInputChange}
                    value={inputValue}
                    onPressEnter={onSubmitClick}
                    onFocus={this.handleInputFocus}
                    ref={this.inputRef}
                />

                <NewSentencePreview
                    gameState={this.props.gameState}
                    inputValue={inputValue}
                    splits={splits}
                    toggleSplit={toggleSplit}
                    onPreviewSettingsChange={this._vm.setPreviewSettings}
                    font={font}
                    colorMode={colorMode}
                    colorScheme={colorScheme}
                    colors={colors}
                    userColors={userColors}
                    sentenceCase={sentenceCase}
                    sentenceSplit={sentenceSplit}
                    background={background}
                    vm={this.props.vm}
                />

                <div className="new-sentence-submit">
                    <Button
                        type="primary"
                        size="large"
                        disabled={!inputValue?.length}
                        onClick={onSubmitClick}
                        style={{ height: '50px', fontSize: '18px', padding: '0 25px' }}
                    >
                        Wyślij <SendOutlined />
                    </Button>
                </div>
            </div>
        )
    }
}

export const NewSentence = observer(forwardRef(NewSentenceComponent));
