import { RadioChangeEvent } from "antd";
import { action, computed, makeObservable, observable } from "mobx";
import { BottomSetting, SentenceMode } from "./SentenceEnums";

export class BaseSentenceViewModel {
    constructor() {
        makeObservable(this);
    }

    @observable
    public colorPickerActive: boolean = false;

    @observable
    public onOverlayClick?: () => void;

    @action
    public onColorSelectStart = (callback: () => void) => {
        this.colorPickerActive = true;
        this.onOverlayClick = callback;
    }

    @action
    public onColorSelectEnd = () => {
        this.colorPickerActive = false;
        this.onOverlayClick = undefined;
    }

    @observable
    protected _selectMode: SentenceMode = SentenceMode.None;

    @computed
    public get selectMode(): SentenceMode {
        if (this.colorPickerActive) {
            return SentenceMode.None;
        }

        return this._selectMode;
    }

    @action
    public onSelectModeChange = (e: RadioChangeEvent) => {
        this._selectMode = e.target.value;
    }

    @observable
    public bottomSetting: BottomSetting = BottomSetting.None;

    @action
    public onBottomRadioChange = (e: RadioChangeEvent) => {
        this.bottomSetting = e.target.value;
    }

    @action
    public onFontSectionClick = () => {
        if (this.bottomSetting === BottomSetting.Font) {
            this.bottomSetting = BottomSetting.None;
        } else {
            this.bottomSetting = BottomSetting.Font;
        }
    }

    @action
    public onColorsSectionClick = () => {
        if (this.bottomSetting === BottomSetting.Colors) {
            this.bottomSetting = BottomSetting.None;
        } else {
            this.bottomSetting = BottomSetting.Colors;
        }
    }

    @action
    public onBackgroundSectionClick = () => {
        if (this.bottomSetting === BottomSetting.Background) {
            this.bottomSetting = BottomSetting.None;
        } else {
            this.bottomSetting = BottomSetting.Background;
        }
    }
}
