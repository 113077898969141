import { observer } from "mobx-react";
import React from 'react';

import { SentenceSplit } from "../../../../model/state";
import { IRadioGroupOption, RadioGroup } from "../../../Components/RadioGroup";
import "./SentenceSplitRadioGroup.css";

export interface ISentenceSplitRadioGroupProps {
    onChange: (value: SentenceSplit) => void;
    value: SentenceSplit;
}

const sentenceSplitOptions: IRadioGroupOption[] = [
    {
        label: <div className="sentence-split-label"><span className="active-syllabe-1">La</span><span className="active-dash">-</span><span className="active-syllabe-2">la</span></div>,
        inactiveLabel: <div className="sentence-split-label"><span>La</span><span>-</span><span>la</span></div>,
        value: SentenceSplit.Show,
        className: 'sentence-split-radio-group-label',
        inactiveClassName: 'sentence-split-radio-group-inactive'
    },
    {
        label: <div><span className="active-syllabe-1">La</span><span className="active-syllabe-2">la</span></div>,
        inactiveLabel: <div>Lala</div>,
        value: SentenceSplit.Hide,
        className: 'sentence-split-radio-group-label',
        inactiveClassName: 'sentence-split-radio-group-inactive'
    }
]

@observer
export class SentenceSplitRadioGroup extends React.Component<ISentenceSplitRadioGroupProps> {
    public render() {
        return (
            <RadioGroup options={sentenceSplitOptions} onSelect={this.props.onChange} value={this.props.value}/>
        )
    }
}
