import classNames from "classnames";
import { observer } from "mobx-react";
import "./RadioGroup.css";
import React from 'react';

export interface IRadioGroupOption {
    label: React.ReactNode;
    inactiveLabel?: React.ReactNode;
    value: any;
    activeClassName?: string;
    inactiveClassName?: string;
    className?: string;
}

export interface IRadioGroupProps {
    options: IRadioGroupOption[];
    onSelect: (value: any) => void;
    value: any;
}

@observer
export class RadioGroup extends React.Component<IRadioGroupProps> {
    public render() {
        const {options, onSelect, value} = this.props;
        return (
            <div className="radio-group">
                {options.map((option: IRadioGroupOption, i: number) => {
                    const {inactiveClassName, activeClassName, className} = option;
                    const iaActive = value === option.value;
                    const classes = classNames({
                        "radio-group-option": true,
                        "radio-group-option-selected": iaActive,
                        "radio-group-option-first": i === 0,
                        "radio-group-option-last": i === options.length - 1,
                        ...(className && {[className]: true}),
                        ...(activeClassName && {[activeClassName]: iaActive}),
                        ...(inactiveClassName && {[inactiveClassName]: !iaActive}),
                    })
                    return <div key={i} style={{right: `${i*2}px`}} className={classes} onClick={() => onSelect(option.value)}>{iaActive ? option.label : option.inactiveLabel ?? option.label}</div>;
                })}
            </div>
        )
    }
}
