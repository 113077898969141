import { Action, endSyllabe, Join, joins, S, SFirstLetterStandalone, SpaceRegexp, WordRegexp } from "./rules";
import { _isJoinPossible, _isNextHit, _isPrevHit, splitByWords } from "./utils";

export interface IResult {
    syl: string[];
    cs: number[];
    ph: string[];
    fs: number[];
}

const _parseRule = (join: Join, currentJoins: string[], currentSplits: number[]): {parsedJoins: string[], forcedSplits: number[]} => {
    const parsedJoins: string[] = [];
    const forcedSplits: number[] = [...currentSplits];
    const isSimpleRule = typeof join === 'string';
    const joinLength = isSimpleRule ? join.length : join.value.length;
    main: for (let startIndex = 0; startIndex < currentJoins.length;) {
        const { isJoinPossible, endIndex }= _isJoinPossible(startIndex, currentJoins, joinLength);
        // is it even possible to construct join of given length
        if (isJoinPossible) {
            const substr = currentJoins.slice(startIndex, endIndex + 1).join("");
            const joinValue = isSimpleRule ? join : join.value;
            // first to check the strings match
            if (RegExp(joinValue).test(substr.toLowerCase())) {
                // CASE #1: join is undonditional, we're always joining
                if (isSimpleRule) {
                    parsedJoins.push(substr);
                    startIndex = endIndex + 1;
                    // todo change splits?
                } else {
                    // ITERATING OVER ALL CONDITIONS
                    for (let conditionIndex = 0; conditionIndex < join.conditions.length; conditionIndex++) {
                        const condition = join.conditions[conditionIndex];
                        const prevHit = _isPrevHit(condition, startIndex, currentJoins);
                        const nextHit = _isNextHit(condition, endIndex + 1, currentJoins);
                        if (prevHit && nextHit) {
                            switch (condition.action) {
                                case Action.ForceSplit:
                                    parsedJoins.push(...currentJoins.slice(startIndex, endIndex + 1));
                                    forcedSplits.push(startIndex);
                                    startIndex = endIndex + 1;
                                    continue main;
                                case Action.ForceSplitBeforeJoin:
                                    forcedSplits.push(parsedJoins.length - 1);
                                    parsedJoins.push(substr);
                                    startIndex = endIndex + 1;
                                    continue main;
                                case Action.ForceSplitAfterJoin:
                                    forcedSplits.push(parsedJoins.length);
                                    parsedJoins.push(substr);
                                    startIndex = endIndex + 1;
                                    continue main;
                                case Action.Join:
                                default:
                                    parsedJoins.push(substr);
                                    startIndex = endIndex + 1;
                                    // todo change splits?
                                    continue main;
                            }
                        }
                    }
                    parsedJoins.push(currentJoins[startIndex]);
                    startIndex++;
                }
            } else {
                parsedJoins.push(currentJoins[startIndex]);
                startIndex++;
            }
        } else {
            parsedJoins.push(currentJoins[startIndex]);
            startIndex++;
        }
    }

    return {
        parsedJoins,
        forcedSplits
    }
}


const _parseJoins = (word: string): {parsedJoins: string[], forcedSplits: number[]} => {
    let parsedJoins = word.split(""); // initially word is split letter by letter
    let forcedSplits: number[] = [];

    for (let j = joins.length - 1; j >= 0; j--) {
        const join = joins[j];
        ({parsedJoins, forcedSplits} = _parseRule(join, parsedJoins, forcedSplits));
    }

    return {
        parsedJoins,
        forcedSplits
    }
}

const _getSPositions = (str: string[]): number[] => {
    const result = [];
    for (let i = 0; i < str.length; i++) {
        if (S.some(s => str[i].toLowerCase().includes(s))) {
            result.push(i);
        }
    }
    return result;
}

const _getSyllabes = (joins: string[], sPositions: number[], forcedSplits: number[]): string[] => {
    let lastCut = 0;
    const result: string[] = [];

    for (let i = 0; i < sPositions.length; i++) {
        if (sPositions[i] === 0) {
            if (forcedSplits.includes(0)) {
                let destEnd = 1;
                result.push(joins.slice(0, destEnd).join(''));
                lastCut = destEnd;
                continue;
            }
            else if (SFirstLetterStandalone.includes(joins[0].toLowerCase())) {
                let destEnd = 1;
                if (forcedSplits.includes(destEnd)) {
                    destEnd++;
                }
                result.push(joins.slice(0, destEnd).join(''));
                lastCut = destEnd;
                continue;
            }
        }

        if (i === sPositions.length - 1) { // last elem
            result.push(joins.slice(lastCut).join(''));
            break;
        }

        const currSIndex = sPositions[i];
        const nextSIndex = sPositions[i + 1];

        if (nextSIndex - currSIndex === 1) { // two S next to each other
            result.push(joins.slice(lastCut, currSIndex + 1).join(''));
            lastCut = currSIndex + 1;
        } else {
            if (endSyllabe.includes(joins[currSIndex].substring(joins[currSIndex].length - 1))) {
                result.push(joins.slice(lastCut, currSIndex + 1).join(''));
                lastCut = currSIndex + 1;
            } else {
                const pos = currSIndex + Math.ceil((nextSIndex - currSIndex) / 2)
                result.push(joins.slice(lastCut, pos).join(''));
                lastCut = pos;
            }
        }
    }

    return result;
}


export const parseWord = (word: string): IResult => {
    if (word.length < 3) {
        return {
            syl: [word],
            cs: [],
            ph: [],
            fs: []
        };
    }

    const { parsedJoins, forcedSplits} = _parseJoins(word);
    const sPositions = _getSPositions(parsedJoins);

    if (sPositions.length <= 1) {
        return {
            syl: [word],
            ph: parsedJoins,
            cs: sPositions,
            fs: forcedSplits
        };

    }

    const syllabes = _getSyllabes(parsedJoins, sPositions, forcedSplits);

    return {
        syl: syllabes,
        ph: parsedJoins,
        cs: sPositions,
        fs: forcedSplits
    };
}

export enum PartType {
    Word = "WORD",
    Space = "SPACE",
    Other = "OTHER"
}

interface IWordPart {
    type: PartType.Word;
    result: IResult;
}

interface ISpacePart {
    type: PartType.Space;
    value: string;
}

interface IOtherPart {
    type: PartType.Other;
    value: string;
}

type Part = IWordPart | ISpacePart | IOtherPart;

export const parseString = (str: string): Part[] => {
    return splitByWords(str).map(part => {
        if (WordRegexp.test(part)) {
            return {
                type: PartType.Word,
                result: parseWord(part)
            }
        } else if (SpaceRegexp.test(part)) {
            return {
                type: PartType.Space,
                value: part
            }
        } else {
            return {
                type: PartType.Other,
                value: part
            }
        }
    });

}
