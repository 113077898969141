import { Alert, Button, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authStore } from "../../model/auth";

const { Title, Paragraph } = Typography;

const VerifyEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const verifyEmailToken = async () => {
      const token = searchParams.get('token');

      if (!token) {
        setStatus('error');
        setErrorMessage('No verification token found');
        return;
      }

      try {
        await authStore.verifyEmail(token);
        setStatus('success');
        // Redirect to login page after 3 seconds
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } catch (error: any) {
        setStatus('error');
        setErrorMessage(error.response?.data?.error || 'Failed to verify email');
      }
    };

    verifyEmailToken();
  }, [searchParams, navigate]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: '24px'
    }}>
      {status === 'loading' && (
        <>
          <Spin size="large" />
          <Title level={4} style={{ marginTop: '16px' }}>
            Verifying your email...
          </Title>
        </>
      )}

      {status === 'success' && (
        <>
          <Alert
            message="Success"
            description="Email verified successfully!"
            type="success"
            showIcon
            style={{ marginBottom: '16px' }}
          />
          <Paragraph>
            Redirecting to login page...
          </Paragraph>
        </>
      )}

      {status === 'error' && (
        <>
          <Alert
            message="Error"
            description={errorMessage}
            type="error"
            showIcon
            style={{ marginBottom: '16px' }}
          />
          <Button
            type="primary"
            onClick={() => navigate('/login')}
          >
            Go to Login
          </Button>
        </>
      )}
    </div>
  );
};

export default VerifyEmail;
