import { observer } from "mobx-react";
import React from 'react';

import { SentenceCase } from "../../../../model/state";
import { IRadioGroupOption, RadioGroup } from "../../../Components/RadioGroup";
import "./SentenceCaseRadioGroup.css";

export interface ISentenceCaseRadioGroupProps {
    onChange: (value: SentenceCase) => void;
    value: SentenceCase;
}

const sentenceCaseOptions: IRadioGroupOption[] = [
    {
        label: <div className="label"><span className="active-letter-1">A</span><span className="active-letter-2">b</span><span className="active-letter-3">c</span></div>,
        inactiveLabel: <div className="label">Abc</div>,
        value: SentenceCase.Original,
        className: 'sentence-case-radio-group-label',
        inactiveClassName: 'sentence-case-radio-group-inactive'
    },
    {
        label: <div className="label"><span className="active-letter-1">A</span><span className="active-letter-2">B</span><span className="active-letter-3">C</span></div>,
        inactiveLabel: <div className="label">ABC</div>,
        value: SentenceCase.Uppercase,
        className: 'sentence-case-radio-group-label',
        inactiveClassName: 'sentence-case-radio-group-inactive'
    }
]

@observer
export class SentenceCaseRadioGroup extends React.Component<ISentenceCaseRadioGroupProps> {
    public render() {
        return (
            <RadioGroup options={sentenceCaseOptions} onSelect={this.props.onChange} value={this.props.value}/>
        )
    }
}
