import { PlusOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Radio } from "antd";
import { CheckboxOptionType } from "antd/es/checkbox/Group";
import { observer } from "mobx-react";
import React from 'react';
import "./ColorManager.css";
import { ColorMode, ColorScheme, IGameState } from "../../../model/state";
import { useColorManager } from './ColorManagerContext';
import { ColorManagerViewModel } from "./ColorManagerViewModel";
import { ColorPicker } from "./ColorPicker";

const options: CheckboxOptionType[] = [
    {label: 'Wyraz', value: ColorMode.Word},
    {label: 'Sylaba', value: ColorMode.Syllabe},
    {label: 'Litera', value: ColorMode.Letter}
]

const options3: CheckboxOptionType[] = [
    {label: 'Wybrane', value: ColorScheme.Predefined},
    {label: 'Losowo', value: ColorScheme.Random},
]

export interface IColorManagerProps {
    gameState: IGameState;
    onColorsChange: (colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[]) => void;
    onColorSelectStart: (callback: () => void) => void;
    onColorSelectEnd: () => void;
    id: string; // Unique identifier for this ColorManager instance
}

// Create a functional wrapper component to use the context hook
const ColorManagerWithContext: React.FC<IColorManagerProps> = (props) => {
    const { isColorManagerActive, setActiveColorManagerId } = useColorManager();
    const isActive = isColorManagerActive(props.id);

    // Create a modified onColorSelectStart that sets this ColorManager as active
    const handleColorSelectStart = (callback: () => void) => {
        setActiveColorManagerId(props.id);
        props.onColorSelectStart(callback);
    };

    // Create a modified onColorSelectEnd that clears the active ColorManager
    const handleColorSelectEnd = () => {
        setActiveColorManagerId(null);
        props.onColorSelectEnd();
    };

    return (
        <ColorManagerClass
            {...props}
            isActive={isActive}
            onColorSelectStart={handleColorSelectStart}
            onColorSelectEnd={handleColorSelectEnd}
        />
    );
};

// Rename the class component and add isActive prop
interface IColorManagerClassProps extends IColorManagerProps {
    isActive: boolean;
}

@observer
export class ColorManagerClass extends React.Component<IColorManagerClassProps> {
    private _vm: ColorManagerViewModel;
    private _listRef = React.createRef<HTMLDivElement>();

    constructor(props: IColorManagerClassProps) {
        super(props);
        this._vm = new ColorManagerViewModel(props)
    }

    public componentDidUpdate() {
        this._vm.updateState(this.props.gameState);
    }

    public render() {
        const { isActive } = this.props;

        // Only apply z-index if this ColorManager is active and color picker is visible
        const shouldApplyZIndex = this._vm.isOverlayVisible && isActive;

        return (
            <div className="color-manager-wrapper">
                {this._vm.isOverlayVisible &&
                    <div className="color-manager-overlay" onClick={this._vm.onOverlayClick}/>}

                <div className="color-manager-buttons-container">
                    <div className="color-manager-mode-options">
                        <Radio.Group options={options}
                                    onChange={(e) => this._vm.setColorMode(e.target.value, this.props.gameState)}
                                    value={this._vm.colorMode}
                                    optionType="button" buttonStyle={'solid'}/>
                    </div>

                    <div className="color-manager-options">
                        <Radio.Group options={options3}
                                    onChange={(e) => this._vm.SetColorScheme(e.target.value, this.props.gameState)}
                                    value={this._vm.colorScheme}
                                    optionType="button" buttonStyle={'solid'}/>
                    </div>
                </div>

                {this._vm.colorScheme === ColorScheme.Random && (
                    <div className="color-manager-random-button">
                        <Button
                            onClick={() => this._vm.onRandomClick(this.props.gameState)}><UndoOutlined/> Losuj</Button>
                    </div>
                )}

                {this._vm.colorScheme === ColorScheme.Predefined && (
                    <div className="color-manager-list" ref={this._listRef}>
                        {this._vm.userColors.map((color, i) => {
                            return (
                                <div key={"color_" + i} style={shouldApplyZIndex ? {zIndex: 6} : undefined}>
                                    <ColorPicker color={color}
                                                 onClick={() => this._vm.onColorPickerClick(i)}
                                                 onChange={color => this._vm.onColorPickerChange(i, color)}
                                                 popoverVisible={i === this._vm.activeColorPicker}
                                                 canRemove={this._vm.userColors.length > 1}
                                                 onRemoveClick={() => this._vm.removeColor(i)}
                                                 containerRef={this._listRef}
                                    />
                                </div>
                            )
                        })}
                        <div className="color-manager-add"
                             style={shouldApplyZIndex ? {zIndex: 5} : {zIndex: 'unset'}}
                             onClick={this._vm.addColor}>
                            <PlusOutlined style={{color: "gray"}}/>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

// Export the wrapper component as the default export
export const ColorManager = ColorManagerWithContext;
