export enum SentenceMode {
    SelectLetter,
    SelectSyllabe,
    SelectWord,
    None,
    Splits
}

export enum BottomSetting {
    Font,
    Colors,
    Background,
    None
} 