import type { PresetStatusColorType } from "antd/es/_util/colors";
import { action, computed, makeObservable, observable } from "mobx";
import { ChangeEvent } from "react";
import {
    ChangeBackgroundMessage,
    ChangeColorMessage,
    ChangeFontMessage,
    ChangeSelectionMessage,
    ChangeSentenceMessage,
    DirectConnectMessage,
    IncomingMessage,
    IncomingType,
    OutgoingType,
    StartGameMessage,
    ToggleCaseMessage,
    ToggleSplitMessage
} from "./model/messageTypes";
import { ColorMode, ColorScheme, ConnectionState, IAppState, Screen, SentenceCase, SentenceSplit } from "./model/state";
import { WS } from "./model/websocket";

export class AppViewModel {

    @observable
    public connectionState = ConnectionState.WSNotConnected;

    @observable
    public initialStateReceived = false;

    @observable
    public inputValue: string = "";

    @observable
    public state: IAppState;

    @observable
    public testSentence: string = "";

    @observable
    public isWaitingForState: boolean = false;

    private stateTimeoutId: number | null = null;

    @action
    public onTestSentenceChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.testSentence = e.currentTarget.value;
    }

    constructor() {
        makeObservable(this);

        this.state = {
            version: 0,
            currentScreen: Screen.Unknown,
            gameState: {
                sentence: '',
                splits: [],
                selection: null,
                sentenceCase: SentenceCase.Uppercase,
                sentenceSplit: SentenceSplit.Show,
                font: '',
                colorMode: ColorMode.Syllabe,
                colorScheme: ColorScheme.Random,
                colors: [],
                userColors: [],
                background: ''
            },
            deviceId: ''
        }

        if (WS.isOpen) {
            this.onConnected()
        }

        console.warn("SUBSCRIBING");

        WS.onOpen.subscribe(this.onConnected);
        WS.onError.subscribe(this.onError);
        WS.onClose.subscribe(this.onClose);

        WS.onMessage.subscribe(this.onMessage);
    }

    private onMessage = action((e: MessageEvent) => {
        try {
            const message: IncomingMessage = JSON.parse(e.data);


            switch(message.type) {
                case IncomingType.DIRECT_CONNECTION_CONFIRMED:
                    console.log("Received DIRECT_CONNECTION_CONFIRMED with state:", message.state);
                    this.state = message.state;
                    // response is directly from a child so we can move to final state and enable the application
                    this.connectionState = ConnectionState.ChildConnected;
                    this.initialStateReceived = true;
                    this.resetWaitingForState();
                    break;
                case IncomingType.SERVER_CONNECTION_CONFIRMED:
                    this.connectionState = message.childConnected ? ConnectionState.ChildConnected : ConnectionState.ServerConnected;
                    break;
                case IncomingType.CHILD_CONNECTED:
                    this.connectionState = ConnectionState.ChildConnected;
                    break;
                case IncomingType.SERVER_CHILD_LOGGED_OUT:
                    this.connectionState = ConnectionState.ServerConnected;
                    break;
                case IncomingType.SYNC_STATE:
                    console.log(`Received ${message.type} with state:`, message.state);
                    this.state = message.state;
                    this.resetWaitingForState();
                    break;
                case IncomingType.PING:
                    break;
                default:
                    console.log("unknown message", message, e);
            }

        } catch(e) {
            console.log("error parsing data");
            console.error(e);
        }
    })

    private onConnected = action(() => {
        // Check if we're in direct mode or remote mode
        if (WS.isDirectMode) {
            // In direct mode with the child app, we use DIRECT_CONNECT
            const connectMessage: DirectConnectMessage = {
                type: OutgoingType.DIRECT_CONNECT // A_1
            };
            WS.send(connectMessage);
        }
        // In remote mode, the WS model will send SERVER_CONNECT after initialization
    })

    private onError = action(() => {
        this.connectionState = ConnectionState.WSNotConnected;
        this.resetWaitingForState();
    })

    private onClose = action(() => {
        this.connectionState = ConnectionState.WSNotConnected;
        this.resetWaitingForState();
    })

    @action
    private setWaitingForState = () => {
        this.isWaitingForState = true;

        // Clear any existing timeout
        if (this.stateTimeoutId !== null) {
            window.clearTimeout(this.stateTimeoutId);
        }

        // Set a new timeout to reset the waiting state after 5 seconds
        this.stateTimeoutId = window.setTimeout(() => {
            if (this.isWaitingForState) {
                console.log("State sync timeout after 5 seconds");
                this.resetWaitingForState();
            }
        }, 5000);
    }

    @action
    private resetWaitingForState = () => {
        this.isWaitingForState = false;

        // Clear the timeout if it exists
        if (this.stateTimeoutId !== null) {
            window.clearTimeout(this.stateTimeoutId);
            this.stateTimeoutId = null;
        }
    }

    public onSubmitSentence = (sentence: string, splits: number[], font: string, colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[], sentenceCase: SentenceCase, sentenceSplit: SentenceSplit, background: string) => {
        this.setWaitingForState();
        const message: ChangeSentenceMessage = {
            type: OutgoingType.CHANGE_SENTENCE,
            sentence,
            splits,
            font,
            colorMode,
            colorScheme,
            colors,
            userColors,
            sentenceCase,
            sentenceSplit,
            background,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    public onStartGameClick = () => {
        const message: StartGameMessage = {
            type: OutgoingType.START_GAME,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    public onSentenceCaseToggle = (checked: boolean) => {
        const message: ToggleCaseMessage = {
            type: OutgoingType.TOGGLE_CASE,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    public onSentenceCaseChange = (sentenceCase: SentenceCase) => {
        const message: ToggleCaseMessage = {
            type: OutgoingType.TOGGLE_CASE,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    public onSentenceSplitToggle = (checked: boolean) => {
        const message: ToggleSplitMessage = {
            type: OutgoingType.TOGGLE_SPLIT,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    public onSentenceSplitChange = (sentenceSplit: SentenceSplit) => {
        const message: ToggleSplitMessage = {
            type: OutgoingType.TOGGLE_SPLIT,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    public onFontChange = (font: string) => {
        const message: ChangeFontMessage = {
            type: OutgoingType.CHANGE_FONT,
            font,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    public onColorsChange = (colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[]) => {
        const message: ChangeColorMessage = {
            type: OutgoingType.CHANGE_COLOR,
            colorMode,
            colorScheme,
            colors,
            userColors,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    public onBackgroundChange = (background: string) => {
        const message: ChangeBackgroundMessage = {
            type: OutgoingType.CHANGE_BACKGROUND,
            background,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    @action
    public onSelectionClick = (start: number, end: number) => {
        const currentSelection = this.state.gameState.selection;
        let selection: {start: number, end: number} | null;
        if (currentSelection?.start === start && currentSelection.end === end) {
            selection = null;
            console.log("selection same, removing", start, end);
        } else {
            selection = {start, end};
            console.log(start, end);
        }

        const message: ChangeSelectionMessage = {
            type: OutgoingType.CHANGE_SELECTION,
            selection,
            version: this.state.version + 1
        };
        WS.send(message);
    }

    @computed
    public get badgeServerStatus(): PresetStatusColorType  {
        switch (this.connectionState) {
            case ConnectionState.WSNotConnected:
                return "error";
            case ConnectionState.WSConnected:
            case ConnectionState.ServerConnected:
                return "warning";
            case ConnectionState.ChildConnected:
                return "success";

        }
    }

    @observable
    public dev: boolean = false;

    @action
    public toggleDev = () => {
        this.dev = !this.dev;
    }

}
