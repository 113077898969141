import { observer } from 'mobx-react';
import React, { Component } from 'react';
import './BackgroundDisplay.css';
import { AppViewModel } from '../AppViewModel';
import backgroundImages, { BackgroundImage } from '../data/backgroundList';

interface BackgroundDisplayProps {
  rows?: number;
  itemsPerRow?: number;
  vm: AppViewModel;
  onSubmit?: boolean;
  onBackgroundSelect?: (filename: string) => void;
}

interface BackgroundDisplayState {
  backgrounds: BackgroundImage[];
  loading: boolean;
  error: string | null;
  currentPage: number;
  selectedBackground: string | null;
}

@observer
class BackgroundDisplay extends Component<BackgroundDisplayProps, BackgroundDisplayState> {
  constructor(props: BackgroundDisplayProps) {
    super(props);
    this.state = {
      backgrounds: [],
      loading: true,
      error: null,
      currentPage: 0,
      selectedBackground: null
    };
  }

  componentDidMount() {
    this.loadBackgrounds();
  }

  componentDidUpdate(prevProps: BackgroundDisplayProps) {
    if (!this.props.onSubmit) {
      const currentBackground = this.props.vm.state.gameState.background;
      if (currentBackground !== this.state.selectedBackground) {
        this.updateSelectedBackground(currentBackground);
      }
    }
  }

  private updateSelectedBackground = (filename: string | null) => {
    if (filename) {
      const selectedBg = backgroundImages.find(bg => bg.filename === filename);
      if (selectedBg) {
        const pageIndex = this.findBackgroundPage(selectedBg);
        this.setState({ selectedBackground: filename, currentPage: pageIndex });
      }
    } else {
      this.setState({ selectedBackground: filename });
    }
  };

  private findBackgroundPage = (background: BackgroundImage): number => {
    const { rows = 2, itemsPerRow = 5 } = this.props;
    const itemsPerPage = rows * itemsPerRow;
    const index = backgroundImages.findIndex(bg => bg.filename === background.filename);
    return Math.floor(index / itemsPerPage);
  };

  private encodeFilePath = (filePath: string): string => {
    return filePath.split('/').map(segment => encodeURIComponent(segment)).join('/');
  };

  private loadBackgrounds = async () => {
    try {
      this.setState({ loading: true });

      this.setState({
        backgrounds: backgroundImages,
        loading: false
      });

      if (!this.props.onSubmit) {
        const currentBackground = this.props.vm.state.gameState.background;
        if (currentBackground) {
          this.updateSelectedBackground(currentBackground);
        }
      }
    } catch (err) {
      console.error('Error loading backgrounds:', err);
      this.setState({
        error: 'Failed to load backgrounds. Please make sure the background list is generated.',
        loading: false
      });
    }
  };

  private handleBackgroundClick = (bg: BackgroundImage) => {
    if (this.props.onSubmit) {
      this.setState({ selectedBackground: bg.filename });
      if (this.props.onBackgroundSelect) {
        this.props.onBackgroundSelect(bg.filename);
      }
    } else {
      if (bg.filename === this.props.vm.state.gameState.background) {
        return;
      }
      this.props.vm.onBackgroundChange(bg.filename);
    }
  };

  private handlePrevPage = () => {
    this.setState(prevState => ({
      currentPage: Math.max(0, prevState.currentPage - 1)
    }));
  };

  private handleNextPage = () => {
    const { rows = 2, itemsPerRow = 5 } = this.props;
    const maxPage = Math.ceil(this.state.backgrounds.length / (rows * itemsPerRow)) - 1;

    this.setState(prevState => ({
      currentPage: Math.min(maxPage, prevState.currentPage + 1)
    }));
  };

  render() {
    const { rows = 2, itemsPerRow = 5 } = this.props;
    const { backgrounds, loading, error, currentPage } = this.state;
    const currentBackground = this.props.onSubmit ? this.state.selectedBackground : this.props.vm.state.gameState.background;

    if (loading) {
      return <div className="background-display loading">Loading backgrounds...</div>;
    }

    if (error) {
      return <div className="background-display error">{error}</div>;
    }

    if (backgrounds.length === 0) {
      return (
        <div className="background-display error">
          <p>No background images found. Please run the following command to generate the background list:</p>
          <pre>npm run process-backgrounds</pre>
          <p>This command will process all background images, rename files with spaces, and generate thumbnails.</p>
        </div>
      );
    }

    const itemsPerPage = rows * itemsPerRow;
    const startIndex = currentPage * itemsPerPage;
    const displayedBackgrounds = backgrounds.slice(startIndex, startIndex + itemsPerPage);
    const hasMorePages = startIndex + itemsPerPage < backgrounds.length;
    const hasPrevPages = currentPage > 0;

    const emptySlots = itemsPerPage - displayedBackgrounds.length;
    const fillerItems = Array(emptySlots).fill(null);

    return (
      <>
        <div className="background-list">
          <div className="background-navigation">
            <button
              className="nav-button prev"
              onClick={this.handlePrevPage}
              disabled={!hasPrevPages}
            >
              <span className="nav-arrow">←</span>
            </button>
            <div className="background-thumbnails"
                 style={{
                   '--rows': rows,
                   '--items-per-row': itemsPerRow
                 } as React.CSSProperties}
            >
              {displayedBackgrounds.map((bg) => (
                <div
                  key={bg.filename}
                  className={`background-thumbnail ${bg.filename === currentBackground ? 'selected' : ''}`}
                  onClick={() => this.handleBackgroundClick(bg)}
                >
                  <img
                    src={`/shared/backgrounds/${this.encodeFilePath(bg.thumbnailPath)}`}
                    alt={bg.filename}
                    title={bg.filename}
                  />
                </div>
              ))}
              {fillerItems.map((_, index) => (
                <div
                  key={`empty-${index}`}
                  className="background-thumbnail empty"
                />
              ))}
            </div>
            <button
              className="nav-button next"
              onClick={this.handleNextPage}
              disabled={!hasMorePages}
            >
              <span className="nav-arrow">→</span>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default BackgroundDisplay;
