import { IResult, parseWord } from "./algorithm";

export const testCases: [word: string, options: string[]][] = [
    ['autobiografia', ['au-to-bio-gra-fia']],
    ['Ala', ['A-la']],
    ['piękno', ['pię-kno', 'pięk-no']],
    ['Lila', ['Li-la']],
    ['nieuk', ['nie-uk']],
    ['Alicja', ['A-li-cja']],
    ['chrząszcze', ['chrząsz-cze', 'chrzą-szcze']],
    ['telewizor', ['te-le-wi-zor']],
    ['karmazynowy', ['kar-ma-zy-no-wy']],
    ['iskra', ['is-kra', 'isk-ra']],
    ['ciekawe', ['cie-ka-we']],
    ['aplikacja', ['a-pli-ka-cja']],
    ['opluskać', ['o-plus-kać']],
    ['nauka', ['na-u-ka']],
    ['nauk', ['na-uk']],
    ['unaukowię', ['u-na-u-ko-wię']],
    ['Kaukaz', ['Kau-kaz']],
    ['puenta', ['puen-ta']],
    ['Paulina', ['Pa-u-li-na']],
    ['zamarzać', ['za-mar-zać']],
    ['zamarznąć', ['za-mar-znąć']],
    // ['nademisja', ['nad-e-mis-ja']],
    ['ponadto', ['po-nad-to']],
    ['nadzwyczajny', ['nad-zwy-czaj-ny']],
    ['nadzienie', ['na-dzie-nie']],
    ['nadźwigać', ['na-dźwi-gać']],
    ['przekomarzać', ['prze-ko-ma-rzać']],
    ['interpozycja', ['in-ter-po-zy-cja']],
    ['udźwig', ['u-dźwig']],
    ['nadrzędny', ['nad-rzę-dny']],
    ['autoemisja', ['au-to-e-mis-ja']],
    ['zbiorniczków', ['zbior-nicz-ków']],
    ['zleceniobiorczyń', ['zle-ce-nio-bior-czyń']],
    ['przedsiębiorczości', ['przed-się-bior-czoś-ci']],
    ['geologia', ['ge-o-lo-gia']],
    ['bezpieczeństwo', ['bez-pie-czeń-stwo']],
    ['usprawniać', ['u-spraw-niać']],
    ['zauważyć', ['za-u-wa-żyć']],
    ['podekscytowane', ['pod-eks-cy-to-wa-ne']],
    ['refleksja', ['ref-lek-sja']],
    ['powietrzu', ['po-wiet-rzu']],
    ['Antek', ['An-tek']],
    ['odkryły', ['od-kry-ły']],
    ['atlas', ['at-las']],
    ['przeglądając', ['prze-glą-da-jąc']],
    ['niektóre', ['nie-któ-re']],
    ['atopowe', ['a-to-po-we']],
    ['nieszczęście', ['nie-szczę-ście']],
    ['algorytm', ['al-go-rytm']],
    ['odbioru', ['od-bio-ru']],
    ['artykułu', ['ar-ty-ku-łu']],
    ['najważniejsze', ['naj-waż-niej-sze']],
    ['zgłoskotwórcza', ['zgłos-ko-twór-cza']],
    ['samogłoskami', ['sa-mo-głos-ka-mi']],
    ['absolutnie', ['ab-so-lut-nie']],
    ['najbardziej', ['naj-bar-dziej']],
    ['historyjek', ['hi-sto-ry-jek']],
    ['przyszła', ['przy-szła']],
    ['zakwita', ['za-kwi-ta']],
    ['paproci', ['pa-pro-ci']],
    ['znajdzie', ['znaj-dzie']],
    ['kwiatu', ['kwia-tu']],
    ['pierwszym', ['pierw-szym']],
    ['odnajdzie', ['od-naj-dzie']],
    ['garnkiem', ['garn-kiem']],
    ['liśćmi', ['liść-mi']],
    ['wyglądał', ['wy-glą-dał']],
    ['zapragniesz', ['za-pra-gniesz']],
    ['pokrywki', ['po-kryw-ki']],
    ['wykłada', ['wy-kła-da']],
    ['zatrzymuje', ['za-trzy-mu-je']],
    ['niebieski', ['nie-bie-ski']],
    ['płatkami', ['płat-ka-mi']],
    ['powietrzu', ['po-wie-trzu']],
    ['bezgłośnie', ['bez-głoś-nie']],
    ['powietrzny', ['po-wietrz-ny']],
    ['aeroplan', ['a-e-ro-plan']],
    ['zmarzliśmy', ['zmar-zliś-my']],
    ['ojciec', ['oj-ciec']],

];

export interface ITestResult {
    syl: IResult;
    valid: boolean;
    options: string[];
}

export const testResults = (): ITestResult[] => {
    return testCases
        .map((tc: [word: string, options: string[]]) => {
            const result: IResult = parseWord(tc[0]);
            return {
                syl: result,
                valid: tc[1].includes(result.syl.join("-")),
                options: tc[1]
            } as ITestResult
        })
}
