import { observer } from "mobx-react";
import React from 'react';
import { Section } from "../../Components/Section";
import "./StatusMessage.css";

export interface IStatusMessageProps {
    title?: string;
    children: React.ReactNode;
}

@observer
export class StatusMessage extends React.Component<IStatusMessageProps> {
    public render() {
        const { title, children } = this.props;

        return (
            <div className="status-message-wrapper">
                <Section title={title}>
                    {children}
                </Section>
            </div>
        )
    }
}
