import classNames from "classnames";
import { observer } from "mobx-react";
import React from 'react';
import { ColorMode, ColorScheme, IGameState } from "../../../model/state";
import { numberOfColors, repeatArray } from "../../../model/utils";
import { SpaceRegexp } from "../../../syllabes/rules";
import { splitBySpace } from "../../../syllabes/utils";
import { SentenceMode } from "../shared/SentenceEnums";
import { SentencePreviewWord } from "./SentencePreviewWord";
import "./SentencePreview.css";

interface ISentencePreview extends IGameState {
    sentenceMode: SentenceMode;
    onSelectionClick?: (start: number, end: number) => void;
    onSentenceClick?: () => void;
    overrideFont?: string;
    singleLine?: boolean;
    onCharacterClick?: (index: number) => void;
}

@observer
export class SentencePreview extends React.Component<ISentencePreview, any> {
    public render() {
        const {
            sentenceMode,
            colorMode,
            colorScheme,
            userColors,
            onSelectionClick,
            onSentenceClick,
            onCharacterClick,
            overrideFont,
            singleLine,
            sentence,
            splits,
            selection,
            font,
            sentenceSplit,
            sentenceCase,
            colors
        } = this.props;
        let offset = 0;
        let colorOffset = 0;
        const sentenceClass = classNames("sentence-preview", {
            "sentence-preview-wrap": !singleLine,
            "sentence-preview-no-wrap": !!singleLine,
        });
        const colorsNeeded = numberOfColors(sentence, splits, colorMode);
        const colorsToUse = colorScheme == ColorScheme.Predefined ? userColors : colors;
        const fullColors = colorsNeeded > colorsToUse.length ? repeatArray(colorsNeeded, colorsToUse) : colorsToUse;
        return (
            <div className={sentenceClass} onClick={onSentenceClick}>
                {splitBySpace(sentence).map((part, i) => {
                    if (!SpaceRegexp.test(part)) {
                        const currOffset = offset;
                        offset += part.length;

                        const splitCount = splits.filter(s => s >= currOffset && s < offset).length;
                        let colorsNeeded = 0;
                        switch (colorMode) {
                            case ColorMode.Word:
                                colorsNeeded = 1;
                                break;
                            case ColorMode.Syllabe:
                                colorsNeeded = splitCount + 1;
                                break;
                            case ColorMode.Letter:
                                colorsNeeded = part.length
                                break;

                        }

                        const appColors = fullColors.slice(colorOffset, colorOffset + colorsNeeded);
                        colorOffset += colorsNeeded;

                        return (<SentencePreviewWord
                            key={i}
                            offset={currOffset}
                            word={part}
                            splits={splits}
                            font={overrideFont || font}
                            colors={appColors}
                            colorMode={colorMode}
                            sentenceCase={sentenceCase}
                            sentenceSplit={sentenceSplit}
                            sentenceMode={sentenceMode}
                            selection={selection}
                            onSelectionClick={onSelectionClick}
                            onCharacterClick={onCharacterClick}
                        />)
                    } else {
                        offset += part.length;
                        return undefined;
                    }

                })}
            </div>
        )
    }
}
