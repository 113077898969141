import { EmptyRegex, ICondition, WordRegexp } from "./rules";

interface IJoinPossibleTrue {
    isJoinPossible: true;
    endIndex: number;
}

interface IJoinPossibleFalse {
    isJoinPossible: false;
    endIndex?: number;
}

type IsJoinPossible = IJoinPossibleTrue | IJoinPossibleFalse;

/**
 * When checking if the join is possible we need to check if we can construct a string of length exactly of that of
 * desired join, while at the beginning all joins are poissble since the works is split letter by letter, later
 * there may already exist some joins that would make creating other joins impossible, that's why the order of the join
 * rules matter here. If the first element's length is the same as join's expected length, we return false as well
 * @param currentIndex position in currentJoins, everything before that is already handled
 * @param currentJoins result parsed so far
 * @param joinLength expected join length
 */
export const _isJoinPossible = (currentIndex: number, currentJoins: string[], joinLength: number): IsJoinPossible => {
    let len = 0;
    for (let i = currentIndex; i < currentJoins.length; i++) {
        if (i === currentIndex) {
            len = currentJoins[i].length;
        } else {
            len += currentJoins[i].length
            if (len === joinLength) {
                return {
                    isJoinPossible: true,
                    endIndex: i
                }
            } else if (len > joinLength) {
                return {
                    isJoinPossible: false
                }
            }
        }
    }

    return {
        isJoinPossible: false
    };
}

export const _countLetters = (str?: string): number => {
    return str?.replace(/[^a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]/g, '').length ?? 0;
}

export const _getPrevLength = (condition: ICondition): number => {
    return condition.prevLength ?? _countLetters(condition.prev);
}

export const _isPrevHit = (condition: ICondition, currentIndex: number, currentJoins: string[]) => {
    const prev = condition.prev;

    if (prev === undefined || prev === '') {
        return true;
    }

    if (prev === EmptyRegex) {
        return currentIndex === 0;
    }

    const prevLength = _getPrevLength(condition);
    const prevStringFull = currentJoins.slice(0, currentIndex).join('');

    if (prevStringFull.length < prevLength) {
        return false;
    }

    const prevString = prevStringFull.substring(prevStringFull.length - prevLength);

    return RegExp(prev).test(prevString.toLowerCase());
}

export const _getNextLength = (condition: ICondition): number => {
    return condition.nextLength ?? _countLetters(condition.next);
}

export const _isNextHit = (condition: ICondition, currentIndex: number, currentJoins: string[]) => {
    const next = condition.next;

    if (next === undefined || next === '') {
        return true;
    }

    const nextLength = _getNextLength(condition);
    const nextStringFull = currentJoins.slice(currentIndex).join('');

    if (nextStringFull.length < nextLength) {
        return false;
    }

    const nextString = nextStringFull.substring(0, nextLength);

    return RegExp(next).test(nextString.toLowerCase());
}

export const firstDiffIndex = (strA: string, strB: string) => {
    for (let i = 0; i < strA.length; i++) {
        if (strA[i] !== strB[i]) {
            return i;
        }
    }
}

export const lastDiffIndex = (strA: string, strB: string) => {
    const diff = strA.length - strB.length;
    for (let i = strA.length - 1; i >= 0; i--) {
        if (strA[i] !== strB[i - diff]) {
            return i + 1;
        }
    }
}

export const splitByWords = (str: string): string[] => {
    return str.match(/[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+|[^a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]+|\s+/g) ?? [];
}

export const splitBySpace = (str: string): string[] => {
    return str.match(/\S+|\s+/g) ?? [];
}

export const getWords = (str: string): string[] => {
    return str.match(/\S+/g) ?? [];
}

export const getWordsBoundaries = (str: string) => {
    const result: [start: number, end: number][] = [];
    let count = 0;
    splitByWords(str).forEach(part => {
        if (WordRegexp.test(part)) {
            result.push([count, count + part.length]);
            count += part.length;
        } else {
            count += part.length;
        }
    })

    return result;
}

export const hasWordsChanged = (strA: string, strB: string) => {
    const bA = getWordsBoundaries(strA);
    const bB = getWordsBoundaries(strB);

    if (bA.length !== bB.length) {
        return true;
    } else {
        for (let i = 0; i < bA.length; i++) {
            const substrA = strA.substring(bA[i][0], bA[i][1]);
            const substrB = strB.substring(bB[i][0], bB[i][1]);
            if (substrA !== substrB) {
                return true;
            }
        }
    }

    return false;

}

export const splitAtIndexes = (str: string, indexes: number[]): string[] => {
    if (!indexes.length) {
        return [str];
    } else if (indexes.length === 1) {
        return [str.substring(0, indexes[0] + 1), str.substring(indexes[0] + 1)];
    } else {
        const result = [];
        // ka-da-mi
        // 1,3
        for (let i = 0; i < indexes.length; i++) {
            if (i === indexes.length - 1) {
                result.push(str.substring(indexes[i] + 1));
            } else if (i === 0) {
                result.push(str.substring(0, indexes[i] + 1)); //substr(0,2)
                result.push(str.substring(indexes[i] + 1, indexes[i+1] + 1)); //substr(0,2)
            } else {
                result.push(str.substring(indexes[i] + 1, indexes[i+1] + 1));
            }
        }

        return result;
    }
}
