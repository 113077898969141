import { Subject } from "rxjs";
import { IAuthResult } from "./auth";
import { WSState } from "./websocket";

export interface IError {
    message: string;
    code?: string;
}

export const OnAuthResult = new Subject<IAuthResult>();
export const OnLogout = new Subject();
export const OnError = new Subject<IError>();
