import { makeAutoObservable } from 'mobx';
import type { PresetStatusColorType } from "antd/es/_util/colors";
import { IAppState } from "../../model/state";
import { WS } from '../../model/websocket';
import { authStore } from '../../model/auth';

/**
 * Menu item type for our ViewModel to avoid React JSX dependencies
 */
interface MenuItemType {
  key: string;
  label: string;
  onClick?: () => void;
  iconType?: string;
}

/**
 * ViewModel for the AppHeader component
 * Handles all business logic and state management
 */
export class AppHeaderViewModel {
  // Local state for header UI
  public showState = false;
  public showLoginControls = false;
  public isSmallScreen = false;

  constructor(
    private appState: IAppState,
    private toggleDevCallback: () => void
  ) {
    makeAutoObservable(this);

    // Initialize small screen detection based on window size
    this.isSmallScreen = window.innerWidth < 768;

    this.showLoginControls = true;

    // Add window resize listener
    window.addEventListener('resize', this.handleWindowResize);
  }

  /**
   * Cleanup when component unmounts
   */
  public dispose = (): void => {
    window.removeEventListener('resize', this.handleWindowResize);
  };

  /**
   * Handle window resize to update isSmallScreen state
   */
  public handleWindowResize = (): void => {
    this.isSmallScreen = window.innerWidth < 768;
  };

  /**
   * Toggle state visibility
   */
  public toggleStateVisibility = (): void => {
    this.showState = !this.showState;
  };

  /**
   * Get admin menu items for dropdown
   */
  public getAdminMenuItems(): MenuItemType[] {
    const items: MenuItemType[] = [
      {
        key: 'toggleState',
        label: this.showState ? "Hide State" : "Show State",
        onClick: this.toggleStateVisibility,
        iconType: 'InfoCircleOutlined'
      },
      {
        key: 'toggleDev',
        label: "Toggle Dev Mode",
        onClick: this.toggleDevCallback,
        iconType: 'SettingOutlined'
      },
      {
        key: 'stateVersion',
        label: `Version: ${this.appState.version}`
      }
    ];

    if (this.appState.deviceId) {
      items.push({
        key: 'deviceId',
        label: `Device ID: ${this.formatDeviceId(this.appState.deviceId)}`
      });
    }

    return items;
  }

  /**
   * Format device ID for display (first 4 and last 4 chars)
   */
  private formatDeviceId(deviceId: string): string {
    return `${deviceId.substring(0, 4)}...${deviceId.substring(deviceId.length - 4)}`;
  }

  // Computed properties for different states

  /**
   * Get connection status text
   */
  public get connectionText(): string {
    if (WS.isDirectMode) {
      return "Bezpośrednie połączenie";
    } else {
      if (authStore.isAuthenticated) {
        return "Połączono z serwerem";
      } else {
        return "Zaloguj się, by połączyć z serwerem";
      }
    }
  }

  /**
   * Get connection status indicator
   */
  public get connectionStatus(): PresetStatusColorType {
    return WS.isOpen ? "success" : "warning";
  }

  /**
   * Check if auth is initialized
   */
  public get isAuthInitialized(): boolean {
    return authStore.isInitialized;
  }

  /**
   * Check if auth is loading
   */
  public get isAuthLoading(): boolean {
    return authStore.isLoading;
  }

  /**
   * Check if refresh was attempted
   */
  public get refreshAttempted(): boolean {
    return authStore.refreshAttempted;
  }

  /**
   * Get current authenticated user
   */
  public get currentUser(): typeof authStore.user {
    return authStore.user;
  }

  /**
   * Check if in direct mode
   */
  public get isDirectMode(): boolean {
    return WS.isDirectMode;
  }

  /**
   * Show login modal
   */
  public showLoginModal = (): void => {
    authStore.showLoginModal();
  };

  /**
   * Show signup modal
   */
  public showSignupModal = (): void => {
    authStore.showSignupModal();
  };

  /**
   * Logout user
   */
  public logout = (): void => {
    authStore.logout();
  };

  /**
   * Get user display name from the user object
   */
  public getUserDisplayName(): string {
    const user = this.currentUser;
    if (!user) return '';
    return user.name || user.email.split('@')[0];
  }

  /**
   * Get user avatar URL
   */
  public getUserAvatar(): string | null {
    return this.currentUser?.avatar || null;
  }
}
