import React, { createContext, ReactNode, useContext, useState } from 'react';

// Define the context type
interface ColorManagerContextType {
    activeColorManagerId: string | null;
    setActiveColorManagerId: (id: string | null) => void;
    isColorManagerActive: (id: string) => boolean;
}

// Create the context with default values
const ColorManagerContext = createContext<ColorManagerContextType>({
    activeColorManagerId: null,
    setActiveColorManagerId: () => {},
    isColorManagerActive: () => false
});

// Create a provider component
interface ColorManagerProviderProps {
    children: ReactNode;
}

export const ColorManagerProvider: React.FC<ColorManagerProviderProps> = ({ children }) => {
    const [activeColorManagerId, setActiveColorManagerId] = useState<string | null>(null);

    const isColorManagerActive = (id: string): boolean => {
        return activeColorManagerId === id || activeColorManagerId === null;
    };

    return (
        <ColorManagerContext.Provider
            value={{
                activeColorManagerId,
                setActiveColorManagerId,
                isColorManagerActive
            }}
        >
            {children}
        </ColorManagerContext.Provider>
    );
};

// Create a hook to use the context
export const useColorManager = () => useContext(ColorManagerContext);
