import { Radio } from 'antd';
import { CheckboxOptionType } from "antd/es/checkbox/Group";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from 'react';
import AnimateHeight from "react-animate-height";
import { AppViewModel } from "../../../AppViewModel";
import './CurrentSentence.css';
import { ColorMode, ColorScheme, IAppState, SentenceCase, SentenceSplit } from "../../../model/state";
import BackgroundDisplay from "../../BackgroundDisplay";
import { FontPicker } from "../../Components/FontPicker";
import { Section, SectionPadding } from "../../Components/Section";
import { ColorManager } from "../ColorManager/ColorManager";
import { SentencePreview } from "../SentencePreview/SentencePreview";
import { SentenceCaseRadioGroup } from "../shared/RadioGroups/SentenceCaseRadioGroup";
import { SentenceSplitRadioGroup } from "../shared/RadioGroups/SentenceSplitRadioGroup";
import { BottomSetting, SentenceMode } from "../shared/SentenceEnums";
import { CurrentSentenceViewModel } from "./CurrentSentenceViewModel";


interface ICurrentSentenceProps {
    state: IAppState;
    vm: AppViewModel;
    onSentenceCaseToggle: (checked: boolean) => void;
    onSentenceCaseChange: (sentenceCase: SentenceCase) => void;
    onSentenceSplitToggle: (checked: boolean) => void;
    onSentenceSplitChange: (sentenceSplit: SentenceSplit) => void;
    onSelectionClick: (start: number, end: number) => void;
    onFontChange: (font: string) => void;
    onColorsChange: (colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[]) => void;
    onBackgroundChange?: (background: string) => void;
}

const options: CheckboxOptionType[] = [
    {label: 'Wyraz', value: SentenceMode.SelectWord},
    {label: 'Sylaba', value: SentenceMode.SelectSyllabe},
    {label: 'Litera', value: SentenceMode.SelectLetter},
];

const bottomOptions: CheckboxOptionType[] = [
    {label: 'Czcionka', value: BottomSetting.Font},
    {label: 'Kolory', value: BottomSetting.Colors},
    {label: 'Tło', value: BottomSetting.Background}
]

@observer
export class CurrentSentence extends React.Component<ICurrentSentenceProps> {
    private _vm!: CurrentSentenceViewModel;
    private sentencePreviewRef = React.createRef<HTMLDivElement>();

    constructor(props: ICurrentSentenceProps) {
        super(props);
        if (!(window as any)._csVM) {
            (window as any)._csVM = new CurrentSentenceViewModel();
        }
        this._vm = (window as any)._csVM;
    }

    public render() {
        const {onSentenceSplitChange, onSentenceCaseChange, onSelectionClick, state, onColorsChange} = this.props;
        const {sentenceSplit, sentenceCase, colorMode, colors} = state.gameState;
        const {
            selectMode,
            onSelectModeChange,
            bottomSetting,
            onFontSectionClick,
            onColorsSectionClick,
            onBottomRadioChange,
            onColorSelectStart,
            onColorSelectEnd,
            onOverlayClick,
            colorPickerActive
        } = this._vm;

        return (
            <>
                <div className="current-sentence-sentence-options">
                    <SentenceCaseRadioGroup onChange={onSentenceCaseChange} value={sentenceCase}/>
                    <SentenceSplitRadioGroup onChange={onSentenceSplitChange} value={sentenceSplit}/>
                </div>
                <div className="current-sentence-sentence-section">
                    <div className="current-sentence-selection-section">
                        <div className="current-sentence-selection-radio">
                            <Radio.Group options={options} onChange={onSelectModeChange} value={selectMode}
                                         optionType="button" buttonStyle={'solid'}/>
                        </div>
                        <div className={classNames("current-sentence-sentence-preview-wrapper", {"current-sentence-sentence-preview-wrapper-active": colorPickerActive})}
                             onClick={onOverlayClick}
                             ref={this.sentencePreviewRef}>
                            <SentencePreview onSelectionClick={onSelectionClick}
                                             sentenceMode={selectMode} {...state.gameState}/>
                        </div>
                        <div className="current-sentence-sentence-font-color-radio">
                            <Radio.Group onChange={onBottomRadioChange} value={bottomSetting}
                                         optionType="button" buttonStyle={'solid'}>
                                <Radio.Button onClick={onFontSectionClick}
                                              value={BottomSetting.Font}>Czcionka</Radio.Button>
                                <Radio.Button onClick={onColorsSectionClick}
                                              value={BottomSetting.Colors}>Kolory</Radio.Button>
                                <Radio.Button onClick={this._vm.onBackgroundSectionClick}
                                              value={BottomSetting.Background}>Tło</Radio.Button>
                            </Radio.Group>
                        </div>

                        <div className="current-sentence-select-font-wrapper">
                            <AnimateHeight duration={500}
                                           height={bottomSetting === BottomSetting.Font ? 'auto' : 0}>
                                <div className="current-sentence-select-font-content">
                                    <Section paddingSize={SectionPadding.Small}>
                                        <FontPicker gameState={state.gameState} onFontChange={this.onFontChange}/>
                                    </Section>
                                </div>
                            </AnimateHeight>
                        </div>
                        <div className="current-sentence-select-color-wrapper">
                            <AnimateHeight duration={500}
                                           height={bottomSetting === BottomSetting.Colors ? 'auto' : 0}>
                                <div className="current-sentence-select-color-content">
                                    <Section paddingSize={SectionPadding.Small}>
                                        <ColorManager gameState={state.gameState}
                                                      onColorsChange={onColorsChange}
                                                      onColorSelectStart={onColorSelectStart}
                                                      onColorSelectEnd={onColorSelectEnd}
                                                      id="current-sentence-color-manager"
                                        />
                                    </Section>
                                </div>
                            </AnimateHeight>
                        </div>
                        <div className="current-sentence-select-background-wrapper">
                            <AnimateHeight duration={500}
                                           height={bottomSetting === BottomSetting.Background ? 'auto' : 0}>
                                <div className="current-sentence-select-background-content">
                                    <Section paddingSize={SectionPadding.Small}>
                                        <BackgroundDisplay vm={this.props.vm} onSubmit={false} onBackgroundSelect={this.props.onBackgroundChange} />
                                    </Section>
                                </div>
                            </AnimateHeight>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    private onFontChange = (font: string) => {
        this.props.onFontChange(font);
        // this._vm.toggleFontSelect();

        // Scroll to the sentence preview using the ref instead of getElementById
        if (this.sentencePreviewRef.current) {
            this.sentencePreviewRef.current.scrollIntoView({behavior: "smooth"});
        }
    }
}
