import classNames from "classnames";
import { observer } from "mobx-react";
import React from 'react';

import './Section.css';

export enum SectionPadding {
    Normal = "normal",
    Small = "small"
}

export interface ISectionTitle {
    id: string;
    title: string;
    activeTitle?: string; // Title to display when active
    inactiveTitle?: string; // Title to display when inactive
    isActive?: boolean;
}

export interface ISectionProps {
    title?: string;
    titles?: ISectionTitle[];
    children?: React.ReactNode;
    paddingSize?: SectionPadding;
    id?: string;
    onTitleClick?: (titleId: string) => void;
    activeContent?: React.ReactNode;
    contentMap?: Record<string, React.ReactNode>;
}

@observer
export class Section extends React.Component<ISectionProps> {
    public render() {
        const { title, titles, children, contentMap, activeContent, onTitleClick } = this.props;
        const paddingSize = this.props.paddingSize ?? SectionPadding.Normal;
        const hasTitles = !!titles && titles.length > 0;
        const hasTitle = !!title;
        const activeTitle = hasTitles ? titles.find(t => t.isActive) : undefined;

        const wrapperClass = classNames("section-wrapper", {
            "section-wrapper-with-title": hasTitle || hasTitles
        });

        const contentClass = classNames(
            "section-content-" + paddingSize,
            {["section-content-no-title-" + paddingSize]: !hasTitle && !hasTitles},
            "section-content-transition"
        );

        // Determine which content to show
        let contentToRender = children;
        if (hasTitles && contentMap && activeTitle) {
            contentToRender = contentMap[activeTitle.id] || activeContent || children;
        }

        return (
            <div id={this.props.id} className={wrapperClass}>
                {hasTitle && <div className="section-title">{title}</div>}

                {hasTitles && (
                    <div className="section-titles-container">
                        {titles.map(titleItem => {
                            // Determine which title text to display
                            let displayTitle = titleItem.title;
                            if (titleItem.isActive && titleItem.activeTitle) {
                                displayTitle = titleItem.activeTitle;
                            } else if (!titleItem.isActive && titleItem.inactiveTitle) {
                                displayTitle = titleItem.inactiveTitle;
                            }

                            return (
                                <div
                                    key={titleItem.id}
                                    className={classNames("section-title", {
                                        "section-title-active": titleItem.isActive,
                                        "section-title-inactive": !titleItem.isActive
                                    })}
                                    onClick={() => onTitleClick && onTitleClick(titleItem.id)}
                                >
                                    {displayTitle}
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className={contentClass}>
                    {contentToRender}
                </div>
            </div>
        );
    }
}
