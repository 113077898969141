import { ConfigProvider, Switch } from "antd";
import { observer } from "mobx-react";
import React from 'react';
import "./Toggle.css";

export interface IToggleProps {
    label?: string;
    checked: React.ReactNode;
    unChecked: React.ReactNode;
    isChecked: boolean;
    onChange: (checked: boolean) => void;
}

@observer
export class Toggle extends React.Component<IToggleProps> {
    public render() {
        const {label, isChecked, unChecked, checked, onChange} = this.props;
        return (
            <div className="toggle">
                {label !== undefined && <div className="toggle-label">{label}</div>}
                <ConfigProvider
                    theme={{
                        token: {
                            fontSize: 20,
                            fontFamily: 'Consolas, monospace'
                        },
                    }}
                >
                    <Switch
                        className="toggle"
                        checkedChildren={checked}
                        unCheckedChildren={unChecked}
                        checked={isChecked}
                        onChange={onChange}
                    />
                </ConfigProvider>
            </div>
        )
    }
}
