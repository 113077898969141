import { IAuthResult } from "./auth";
import { IStateMessage } from "./state";


export enum IncomingType {
    // Connection messages (used in both DIRECT and SERVER modes)
    DIRECT_CONNECTION_CONFIRMED = 'DIRECT_CONNECTION_CONFIRMED', // A_2
    SERVER_CONNECTION_CONFIRMED = 'SERVER_CONNECTION_CONFIRMED', // J_2

    // Authentication responses (Both modes, but handled differently)
    DIRECT_LOGIN_PARENT = 'DIRECT_LOGIN_PARENT', // D_4

    // Logout notification (when the child logs out - DIRECT mode only)
    DIRECT_LOGOUT_PARENT = 'DIRECT_LOGOUT_PARENT', // F_4

    // State synchronization (Both modes)
    SYNC_STATE = 'SYNC_STATE', // B_1

    // remote child logged in
    SERVER_CHILD_LOGGED_IN = 'SERVER_CHILD_LOGGED_IN', // D_3
    SERVER_CHILD_LOGGED_OUT = 'SERVER_CHILD_LOGGED_OUT', // F_3

    SERVER_LOGIN_CHILD_PARENT_RESULT = 'SERVER_LOGIN_CHILD_PARENT_RESULT',  // M_2

    CHILD_CONNECTED = 'CHILD_CONNECTED',

    // Heartbeat
    PING = 'PING'  // Server → Client: Heartbeat ping
}


export interface BaseIncomingMessage<T extends IncomingType> {
    type: T;
}

export interface IDirectConnectionConfirmed extends IStateMessage, BaseIncomingMessage<IncomingType.DIRECT_CONNECTION_CONFIRMED> { } // A_2
export interface IServerConnectionConfirmed extends BaseIncomingMessage<IncomingType.SERVER_CONNECTION_CONFIRMED> { // J_2
    childConnected?: boolean;
    success: boolean;
    error?: string;
}
export interface IDirectLoginMessage extends IAuthResult, BaseIncomingMessage<IncomingType.DIRECT_LOGIN_PARENT> { } // D_4
export interface ISyncStateMessage extends IStateMessage, BaseIncomingMessage<IncomingType.SYNC_STATE> { } // B_1
export interface LogoutNotificationMessage extends BaseIncomingMessage<IncomingType.DIRECT_LOGOUT_PARENT> { } // F_4
export interface IServerChildLoggedIn extends IStateMessage, BaseIncomingMessage<IncomingType.SERVER_CHILD_LOGGED_IN> { } // D_3
export interface IServerChildLoggedOut extends BaseIncomingMessage<IncomingType.SERVER_CHILD_LOGGED_OUT> { } // D_3
export interface IServerChildConnected extends BaseIncomingMessage<IncomingType.CHILD_CONNECTED> { } // D_3
export interface PingMessage extends BaseIncomingMessage<IncomingType.PING> {
    timestamp: number; // Server timestamp for measuring round-trip time
}

export type IncomingMessage =
    IDirectConnectionConfirmed |
    IServerConnectionConfirmed |
    IDirectLoginMessage |
    ISyncStateMessage |
    LogoutNotificationMessage |
    IServerChildLoggedIn |
    IServerChildLoggedOut |
    IServerChildConnected |
    PingMessage;



export enum OutgoingType {
    // Connection establishment (both modes)
    DIRECT_CONNECT = 'DIRECT_CONNECT', // A_1
    SERVER_CONNECT = 'SERVER_CONNECT', // J_1

    // Authentication (DIRECT mode)
    DIRECT_LOGIN = 'DIRECT_LOGIN', // E_1

    // Logout notification (DIRECT mode only)
    DIRECT_LOGOUT = 'DIRECT_LOGOUT', // G_1

    SERVER_LOGIN_CHILD_PARENT = 'SERVER_LOGIN_CHILD_PARENT', // M_1

    // Game control messages (Both modes)
    CHANGE_SENTENCE = 'CHANGE_SENTENCE', // C_1
    CHANGE_SELECTION = 'CHANGE_SELECTION',  // C_1
    START_GAME = 'START_GAME',  // C_1
    TOGGLE_CASE = 'TOGGLE_CASE',  // C_1
    TOGGLE_SPLIT = 'TOGGLE_SPLIT',  // C_1
    CHANGE_FONT = 'CHANGE_FONT', // C_1
    CHANGE_COLOR = 'CHANGE_COLOR', // C_1
    CHANGE_BACKGROUND = 'CHANGE_BACKGROUND', // C_1

    // Heartbeat response
    PONG = 'PONG'  // Client → Server: Heartbeat pong response
}


export interface BaseOutgoingMessage<T extends OutgoingType> {
    type: T;
}

interface IVersionedMessage {
    version: number;
}

export interface DirectLoginMessage extends BaseOutgoingMessage<OutgoingType.DIRECT_LOGIN> {
    email: string;
    password: string;
}

export interface ServerConnectMessage extends BaseOutgoingMessage<OutgoingType.SERVER_CONNECT> { // J_1
    token: string;
}
export interface DirectConnectMessage extends BaseOutgoingMessage<OutgoingType.DIRECT_CONNECT> { }

export interface ChangeSentenceMessage extends IVersionedMessage, BaseOutgoingMessage<OutgoingType.CHANGE_SENTENCE> {
    sentence: string;
    splits: number[];
    font: string;
    colorMode: string;
    colorScheme: string;
    colors: string[];
    userColors: string[];
    sentenceCase: string;
    sentenceSplit: string;
    background: string;
}

export interface ChangeSelectionMessage extends IVersionedMessage, BaseOutgoingMessage<OutgoingType.CHANGE_SELECTION> {
    selection: {start: number, end: number} | null;
}

export interface StartGameMessage extends IVersionedMessage, BaseOutgoingMessage<OutgoingType.START_GAME> {}
export interface ToggleCaseMessage extends IVersionedMessage, BaseOutgoingMessage<OutgoingType.TOGGLE_CASE> {}
export interface ToggleSplitMessage extends IVersionedMessage, BaseOutgoingMessage<OutgoingType.TOGGLE_SPLIT> {}
export interface ChangeFontMessage extends IVersionedMessage, BaseOutgoingMessage<OutgoingType.CHANGE_FONT> {
    font: string;
}

export interface ChangeColorMessage extends IVersionedMessage, BaseOutgoingMessage<OutgoingType.CHANGE_COLOR> {
    colorMode: string;
    colorScheme: string;
    colors: string[];
    userColors: string[];
}


export interface ChangeBackgroundMessage extends IVersionedMessage, BaseOutgoingMessage<OutgoingType.CHANGE_BACKGROUND> {
    background: string;
}

export interface PongMessage extends BaseOutgoingMessage<OutgoingType.PONG> {
    timestamp: number; // Echo back the timestamp from the ping
}

export type OutgoingMessage =
    DirectLoginMessage |
    ServerConnectMessage |
    DirectConnectMessage |
    ChangeSentenceMessage |
    ChangeSelectionMessage |
    StartGameMessage |
    ToggleCaseMessage |
    ToggleSplitMessage |
    ChangeFontMessage |
    ChangeColorMessage |
    ChangeBackgroundMessage |
    PongMessage;
