import { Radio } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from 'react';
import AnimateHeight from "react-animate-height";
import { AppViewModel } from "../../../AppViewModel";
import BackgroundDisplay from "../../../components/BackgroundDisplay";
import { ColorMode, ColorScheme, IGameState, SentenceCase, SentenceSplit } from "../../../model/state";
import { FontPicker } from "../../Components/FontPicker";
import { Section, SectionPadding } from "../../Components/Section";
import { ColorManager } from "../ColorManager/ColorManager";
import { SentencePreview } from "../SentencePreview/SentencePreview";
import { SentenceCaseRadioGroup } from "../shared/RadioGroups/SentenceCaseRadioGroup";
import { SentenceSplitRadioGroup } from "../shared/RadioGroups/SentenceSplitRadioGroup";
import "./NewSentencePreview.css";
import { BottomSetting, SentenceMode } from "../shared/SentenceEnums";
import { NewSentencePreviewViewModel } from "./NewSentencePreviewViewModel";

interface INewSentencePreviewProps {
    gameState: IGameState;
    inputValue: string;
    splits: number[];
    toggleSplit: (index: number) => void;
    onPreviewSettingsChange: (
        font: string,
        colorMode: ColorMode,
        colorScheme: ColorScheme,
        colors: string[],
        userColors: string[],
        sentenceCase: SentenceCase,
        sentenceSplit: SentenceSplit,
        background: string
    ) => void;
    font: string;
    colorMode: ColorMode;
    colorScheme: ColorScheme;
    colors: string[];
    userColors: string[];
    sentenceCase: SentenceCase;
    sentenceSplit: SentenceSplit;
    background: string;
    vm: AppViewModel;
}

@observer
export class NewSentencePreview extends React.Component<INewSentencePreviewProps> {
    private _vm: NewSentencePreviewViewModel;

    constructor(props: INewSentencePreviewProps) {
        super(props);
        if (!(window as any)._nspVM) {
            (window as any)._nspVM = new NewSentencePreviewViewModel();
        }
        this._vm = (window as any)._nspVM;
    }

    render() {
        const {
            inputValue,
            splits,
            toggleSplit,
            gameState,
            font,
            colorMode,
            colorScheme,
            colors,
            userColors,
            sentenceCase,
            sentenceSplit,
            vm
        } = this.props;

        // If inputValue is empty, don't render the preview
        if (!inputValue || inputValue.trim() === '') {
            return null;
        }

        const {
            bottomSetting,
            onBottomRadioChange,
            onFontSectionClick,
            onColorsSectionClick,
            onColorSelectStart,
            onColorSelectEnd,
            colorPickerActive,
            onOverlayClick
        } = this._vm;

        // Create a preview gameState that combines actual gameState with preview settings
        const previewGameState = {
            ...gameState,
            sentence: inputValue,
            splits: splits,
            font: font || gameState.font,
            colorMode: colorMode,
            colorScheme: colorScheme,
            colors: colors.length > 0 ? colors : gameState.colors,
            userColors: userColors.length > 0 ? userColors : gameState.userColors,
            sentenceCase: sentenceCase,
            sentenceSplit: sentenceSplit
        };

        return (
            <div className="new-sentence-preview-section">
                <div className="new-sentence-sentence-options">
                    <SentenceCaseRadioGroup onChange={this.onSentenceCaseChange} value={sentenceCase}/>
                    <SentenceSplitRadioGroup onChange={this.onSentenceSplitChange} value={sentenceSplit}/>
                </div>
                <div className="new-sentence-sentence-section">
                    <div className="new-sentence-selection-section">
                        <div className={classNames("new-sentence-preview-wrapper", {"new-sentence-preview-wrapper-active": colorPickerActive})}
                             onClick={onOverlayClick}>
                            <SentencePreview
                                sentenceMode={SentenceMode.Splits}
                                onCharacterClick={toggleSplit}
                                {...previewGameState}
                            />
                        </div>
                        <div className="new-sentence-preview-font-color-radio">
                            <Radio.Group onChange={onBottomRadioChange} value={bottomSetting}
                                         optionType="button" buttonStyle={'solid'}>
                                <Radio.Button onClick={onFontSectionClick}
                                              value={BottomSetting.Font}>Czcionka</Radio.Button>
                                <Radio.Button onClick={onColorsSectionClick}
                                              value={BottomSetting.Colors}>Kolory</Radio.Button>
                                <Radio.Button onClick={this._vm.onBackgroundSectionClick}
                                              value={BottomSetting.Background}>Tło</Radio.Button>
                            </Radio.Group>
                        </div>

                        <div className="new-sentence-preview-font-wrapper">
                            <AnimateHeight duration={500}
                                           height={bottomSetting === BottomSetting.Font ? 'auto' : 0}>
                                <div className="new-sentence-preview-font-content">
                                    <Section paddingSize={SectionPadding.Small}>
                                        <FontPicker gameState={previewGameState} onFontChange={this.onFontChange}/>
                                    </Section>
                                </div>
                            </AnimateHeight>
                        </div>

                        <div className="new-sentence-preview-color-wrapper">
                            <AnimateHeight duration={500}
                                           height={bottomSetting === BottomSetting.Colors ? 'auto' : 0}>
                                <div className="new-sentence-preview-color-content">
                                    <Section paddingSize={SectionPadding.Small}>
                                        <ColorManager
                                            gameState={previewGameState}
                                            onColorsChange={this.onColorsChange}
                                            onColorSelectStart={onColorSelectStart}
                                            onColorSelectEnd={onColorSelectEnd}
                                            id="new-sentence-color-manager"
                                        />
                                    </Section>
                                </div>
                            </AnimateHeight>
                        </div>

                        <div className="new-sentence-preview-background-wrapper">
                            <AnimateHeight duration={500}
                                           height={bottomSetting === BottomSetting.Background ? 'auto' : 0}>
                                <div className="new-sentence-preview-background-content">
                                    <Section paddingSize={SectionPadding.Small}>
                                        <BackgroundDisplay
                                            vm={vm}
                                            onSubmit={true}
                                            onBackgroundSelect={this.onBackgroundSelect}
                                        />
                                    </Section>
                                </div>
                            </AnimateHeight>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onSentenceCaseChange = (sentenceCase: SentenceCase) => {
        this.props.onPreviewSettingsChange(
            this.props.font,
            this.props.colorMode,
            this.props.colorScheme,
            this.props.colors,
            this.props.userColors,
            sentenceCase,
            this.props.sentenceSplit,
            this.props.background
        );
    }

    private onSentenceSplitChange = (sentenceSplit: SentenceSplit) => {
        this.props.onPreviewSettingsChange(
            this.props.font,
            this.props.colorMode,
            this.props.colorScheme,
            this.props.colors,
            this.props.userColors,
            this.props.sentenceCase,
            sentenceSplit,
            this.props.background
        );
    }

    private onFontChange = (font: string) => {
        this.props.onPreviewSettingsChange(
            font,
            this.props.colorMode,
            this.props.colorScheme,
            this.props.colors,
            this.props.userColors,
            this.props.sentenceCase,
            this.props.sentenceSplit,
            this.props.background
        );
    }

    private onColorsChange = (colorMode: ColorMode, colorScheme: ColorScheme, colors: string[], userColors: string[]) => {
        this.props.onPreviewSettingsChange(
            this.props.font,
            colorMode,
            colorScheme,
            colors,
            userColors,
            this.props.sentenceCase,
            this.props.sentenceSplit,
            this.props.background
        );
    }

    private onBackgroundSelect = (background: string) => {
        this.props.onPreviewSettingsChange(
            this.props.font,
            this.props.colorMode,
            this.props.colorScheme,
            this.props.colors,
            this.props.userColors,
            this.props.sentenceCase,
            this.props.sentenceSplit,
            background
        );
    }
}
