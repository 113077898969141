import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App } from "./App";
import { AppViewModel } from "./AppViewModel";
import VerifyEmail from "./components/pages/VerifyEmail";

const vm = new AppViewModel();

ReactDOM.render(
    <ConfigProvider
        theme={{
            components: {
                Radio: {
                    buttonBg: 'cornsilk',
                    // buttonCheckedBg: '#ffe683',
                    colorBorder: 'burlywood',
                    colorBorderSecondary: 'red',
                    colorBorderBg: 'red',
                    buttonCheckedBg: 'cornsilk  ',
                    colorPrimaryBorder: '2px solid red',
                    colorPrimaryBorderHover: 'red',
                    lineWidth: 2,
                    fontSize: 16,
                    fontFamily: 'Consolas, monospace',
                    // colorPrimary: '#ffe683',
                    // colorPrimaryHover: 'red',
                    // colorBorderBg: 'blue',
                    // colorBorderSecondary: 'blue',
                    // colorPrimaryBorderHover: 'blue',
                    // buttonSolidCheckedBg: 'burlywood',
                },
            },
        }}
    >
        <BrowserRouter>
            <Routes>
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="*" element={<App vm={vm} />} />
            </Routes>
        </BrowserRouter>
    </ConfigProvider>,
    // @ts-ignore
    document.getElementById('root')
)
