import {
  LinkOutlined,
  LogoutOutlined,
  UserOutlined,
  MenuOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  LoginOutlined,
  UserAddOutlined,
  ProfileOutlined
} from "@ant-design/icons";
import { Avatar, Badge, Button, Dropdown, Spin, Space, MenuProps, Typography } from "antd";
import { Header } from "antd/es/layout/layout";
import { observer } from "mobx-react";
import React from "react";

import { IAppState } from "../../model/state";
import { LoginModal } from "../Auth/LoginModal";
import { SignupModal } from "../Auth/SignupModal";
import { AppHeaderViewModel } from "./AppHeaderViewModel";
import './AppHeader.css';

const { Text } = Typography;

export interface IAppHeaderProps {
    state: IAppState,
    toggleDev: () => void;
}

export const AppHeader = observer(class AppHeader extends React.Component<IAppHeaderProps> {
    private readonly viewModel: AppHeaderViewModel;

    constructor(props: IAppHeaderProps) {
        super(props);
        this.viewModel = new AppHeaderViewModel(props.state, props.toggleDev);
    }

    componentWillUnmount() {
        // Clean up event listeners managed by the ViewModel
        this.viewModel.dispose();
    }

    public render() {
        const { isSmallScreen, showState, showLoginControls } = this.viewModel;
        const { isAuthInitialized, isAuthLoading, refreshAttempted, currentUser } = this.viewModel;

        // Create menu items for admin dropdown
        const adminMenuItems = this.getAdminMenuItems();

        // Create user dropdown menu items
        const userMenuItems: MenuProps['items'] = [
            {
                key: 'profile',
                icon: <ProfileOutlined />,
                label: 'Profil użytkownika'
            },
            {
                type: 'divider'
            },
            {
                key: 'logout',
                icon: <LogoutOutlined />,
                label: 'Wyloguj',
                danger: true,
                onClick: this.viewModel.logout
            }
        ];

        return (
            <Header className="app-header">
                {/* Left side - Logo and admin controls */}
                <div className="app-header__left">
                    <div className="app-header__logo">
                        Sylaby
                    </div>

                    <Space size="middle">
                        <Text className="app-header__connection">
                            <LinkOutlined />
                            {this.viewModel.connectionText}
                        </Text>
                        <Badge status={this.viewModel.connectionStatus}/>
                        <Dropdown menu={{ items: adminMenuItems }} trigger={['click']}>
                            <Button
                                type="text"
                                icon={<MenuOutlined />}
                                size="small"
                                className="app-header__menu-button"
                            >
                                <span>Menu</span>
                            </Button>
                        </Dropdown>
                    </Space>
                </div>

                {/* Right side - Auth controls */}
                <div className="app-header__right">
                    {(!isAuthInitialized || (isAuthLoading && !refreshAttempted)) ? (
                        <Spin />
                    ) : currentUser ? (
                        <Dropdown menu={{ items: userMenuItems }}>
                            <div className="app-header__user">
                                <Avatar
                                    icon={<UserOutlined/>}
                                    src={this.viewModel.getUserAvatar()}
                                    size="small"
                                />
                                {!isSmallScreen && (
                                    <span className="app-header__username">
                                        {this.viewModel.getUserDisplayName()}
                                    </span>
                                )}
                            </div>
                        </Dropdown>
                    ) : showLoginControls ? (
                        <Space className="app-header__auth-buttons">
                            <Button
                                size="middle"
                                onClick={this.viewModel.showLoginModal}
                                icon={<LoginOutlined />}
                            >
                                Zaloguj
                            </Button>
                            {!this.viewModel.isDirectMode && (
                                <Button
                                    type="primary"
                                    size="middle"
                                    onClick={this.viewModel.showSignupModal}
                                    icon={<UserAddOutlined />}
                                >
                                    Załóż konto
                                </Button>
                            )}
                        </Space>
                    ) : (
                        <Spin />
                    )}
                </div>

                {/* State debug display */}
                {showState && (
                    <div className="app-header__state-debug">
                        <pre>
                            {JSON.stringify(this.props.state, undefined, 2)}
                        </pre>
                    </div>
                )}

                <LoginModal/>
                <SignupModal/>
            </Header>
        )
    }

    // Convert ViewModel menu items to Ant Design menu items
    private getAdminMenuItems = (): MenuProps['items'] => {
        const viewModelItems = this.viewModel.getAdminMenuItems();

        return viewModelItems.map(item => {
            const menuItem: any = {
                key: item.key,
                label: item.label,
                onClick: item.key === 'toggleState' ? this.viewModel.toggleStateVisibility :
                         item.key === 'toggleDev' ? this.props.toggleDev :
                         undefined
            };

            // Add icon if specified
            if (item.iconType) {
                switch (item.iconType) {
                    case 'InfoCircleOutlined':
                        menuItem.icon = <InfoCircleOutlined />;
                        break;
                    case 'SettingOutlined':
                        menuItem.icon = <SettingOutlined />;
                        break;
                }
            }

            return menuItem;
        });
    }
});
