export enum ConnectionState {
    WSNotConnected,
    WSConnected,
    ServerConnected,
    ChildConnected
}

export enum Screen {
    Title = "TITLE",
    Game = "GAME",
    Unknown = "UNKNOWN"
}

export enum SentenceCase {
    Original = "ORIGINAL",
    Uppercase = "UPPERCASE"
}

export enum SentenceSplit {
    Show = "SHOW",
    Hide = "HIDE"
}

export enum ColorMode {
    Word = "WORD",
    Syllabe = "SYLLABE",
    Letter = "LETTER"
}

export enum ColorScheme {
    Random = "RANDOM",
    Predefined = "PREDEFINED"
}

export interface IGameState {
    sentence: string;
    splits: number[];
    selection: { start: number, end: number } | null;
    sentenceCase: SentenceCase;
    sentenceSplit: SentenceSplit;
    font: string;
    colorMode: ColorMode;
    colorScheme: ColorScheme;
    colors: string[];
    userColors: string[];
    background: string;
}

export interface IAppState {
    version: number;
    currentScreen: Screen;
    gameState: IGameState;
    deviceId?: string;
}

export interface IStateMessage {
    state: IAppState;
}
